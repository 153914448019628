const Code = {
  CA: "CA",
  CR: "CR",
  FP: "FP",
  FT: "FT",
  IA: "IA",
  IC: "IC",
  IF: "IF",
  IZ: "IZ",
  NA: "NA",
  NO: "NO",
  NP: "NP",
  NW: "NW",
  WE: "WE"
};

export default Code;
