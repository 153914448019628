const createStorage = (key) => {
  return {
    remove: () => window.localStorage.removeItem(key),
    get: () => window.localStorage.getItem(key),
    set: (value) => window.localStorage.setItem(key, value)
  };
};

export const walletChoiceLocalStorage = createStorage("walletChoice");
export const networkChoiceLocalStorage = createStorage("networkChoice");
