<template>
  <div class="network uk-flex uk-flex-left uk-flex-middle uk-text-small">
    <i
      v-if="!!state"
      class="fal fa-chart-network uk-margin-small-left uk-margin-small-right"
    />
    <img
      v-else-if="network.includes('polygon')"
      class="uk-margin-small-left uk-margin-small-right"
      height="20"
      width="20"
      uk-svg
      src="/polygonLogo.svg"
      :alt="network"
    />
    <img
      v-else-if="network.includes('arbitrum')"
      class="uk-preserve uk-margin-small-left uk-margin-small-right"
      height="20"
      width="20"
      uk-svg
      src="/arbitrumLogo.svg"
      :alt="network"
    />
    <img
      v-else
      height="20"
      width="20"
      uk-svg
      :class="`${network}-color uk-margin-small-left uk-margin-small-right`"
      src="/ethereumLogo.svg"
      :alt="network"
    />
    <span>
      {{ networkDisplayName }}
    </span>
  </div>
</template>

<script>
import { NETWORK_NAMES } from "@/utils/constants/networks";
import VueScreenSize from "vue-screen-size";

export default {
  name: "Network",
  props: {
    network: { type: String },
    state: {
      type: String,
      validator: function (value) {
        return ["connecting", "disconnected"].includes(value);
      },
    },
  },
  mixins: [VueScreenSize.VueScreenSizeMixin],
  computed: {
    networkDisplayName() {
      return this.state === "connecting"
        ? "connecting..."
        : this.state === "disconnected"
        ? "disconnected"
        : this.$vssWidth >= 640
        ? NETWORK_NAMES[this.network]
        : NETWORK_NAMES[this.network].split(" ")[0];
    },
  },
};
</script>
