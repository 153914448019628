const Errors = [
  {
    operation: "getAddress",
    code: "UNSUPPORTED_OPERATION",
    message: "Wallet not connected"
  }
];

/**
 * Returns whether the error message should be formatted by this module.
 * @param {String} message
 */
const Filter = (message) => {
  return (
    message.includes(`operation="`) &&
    message.includes(`code=`) &&
    message.includes(`version=providers/`)
  );
};

/**
 * Attempts to format the message into an error message.
 * @param {String} message
 */
const Format = (message) => {
  for (let error of Errors) {
    if (!message.includes(`code=${error.code}`)) continue;
    if (!message.includes(`operation="${error.operation}"`)) continue;
    return error.message;
  }
  return message;
};

export default {
  Errors,
  Filter,
  Format
};
