<template>
  <div>
    <nav
      id="header"
      class="uk-container-fluid uk-flex uk-padding-small"
      uk-navbar
    >
      <div class="uk-navbar-left">
        <a href="../" class="uk-logo uk-flex uk-flex-middle uk-visible@s">
          <img
            width="40"
            uk-svg
            src="../assets/images/handle.fiLogoCutInverted.svg"
            class="uk-margin-small-right"
          />
          <span> handle.fi </span>
        </a>
        <a
          class="uk-navbar-toggle uk-hidden@l"
          uk-toggle="target: #sidenav"
          uk-navbar-toggle-icon
          v-if="!isLite"
        ></a>

        <div
          class="uk-margin-small-left hfi-warning uk-margin-xsmall-top cursor-pointer"
          uk-tooltip="title: this app is in beta -<br/> use at your own risk; pos: bottom; cls: uk-active hfi-warning;"
        >
          <i class="fal fa-exclamation-triangle" />
          beta
        </div>
      </div>

      <div v-if="!hideContent" class="uk-navbar-right">
        <ul
          v-if="!isShop"
          class="uk-navbar-nav uk-visible@l uk-margin-remove-bottom"
        >
          <template v-if="!isLite">
            <li><router-link to="/">dashboard</router-link></li>
            <li>
              <a id="menu-item" href="#">
                fxTokens
                <i class="fal fa-chevron-down uk-margin-small-left" />
              </a>
              <div
                class="uk-flex-center uk-navbar-dropdown hfi-menu-dropdown"
                style="width: auto"
                uk-drop="align: left; delay-hide: 0; pos: bottom-justify; boundary: #menu-item; offset: 8;"
              >
                <ul class="uk-nav uk-navbar-dropdown-nav">
                  <li><router-link to="/borrow">borrow</router-link></li>
                  <li><router-link to="/repay">repay</router-link></li>
                  <!--<li><router-link to="/redeem">redeem</router-link></li>-->
                </ul>
              </div>
            </li>
            <li><router-link to="/earn">earn</router-link></li>
            <li><router-link to="/convert">convert</router-link></li>
            <li><router-link to="/trade">trade</router-link></li>
            <li><router-link to="/bridge">bridge</router-link></li>
          </template>
        </ul>

        <ul
          v-if="isShop"
          class="uk-navbar-nav uk-visible@m uk-margin-remove-bottom"
        >
          <li><a href="https://handle.fi">home</a></li>
          <li><a to="/shop">shop</a></li>
        </ul>

        <button
          class="uk-icon-button hfi-dropdown-button uk-margin-small-left uk-margin-small-right uk-margin-remove-top"
          uk-toggle="#settings-modal"
          :disabled="!account"
          :uk-tooltip="
            account ? 'title: adjust settings; pos: bottom;' : undefined
          "
        >
          <i class="fal fa-sliders-h" />
        </button>

        <button
          v-if="isLite && account"
          class="uk-button uk-button-small hfi-button uk-width-expand"
          @click="disconnectWallet"
        >
          <i class="fal fa-sign-out uk-margin-xsmall-right"></i>
          sign out
        </button>

        <a
          class="uk-button uk-button-small hfi-button uk-margin-small-left hfi-forex-button forex-button uk-visible@m"
          v-if="!isLite && account"
          :disabled="false"
        >
          {{ formatEther(forexBalance, 2) }}
          FOREX
          <img
            style="margin-top: -4px"
            src="handle.fiFOREXLogoLightBorder.svg"
            alt="handle.fi FOREX logo"
            width="18"
          />
        </a>
        <div
          class="uk-navbar-dropdown uk-flex-column uk-flex-center uk-width-auto forex-dropdown"
          uk-dropdown="mode: click; offset: -2; pos: bottom-justify; boundary: .hfi-forex-button"
        >
          <button
            :class="`uk-button uk-button-xsmall hfi-button hfi-forex-button uk-width-expand ${
              validNetworkForForex ? '' : 'disabled-opacity'
            }`"
            :disabled="!validNetworkForForex"
            @click="convert"
            :uk-tooltip="
              validNetworkForForex ? `title: buy FOREX; pos: bottom` : undefined
            "
          >
            buy
          </button>
          <button
            class="uk-button uk-button-xsmall hfi-button hfi-forex-button uk-width-expand uk-margin-small-top"
            @click="addForexToWallet"
            uk-tooltip="title: add FOREX token to wallet; pos: bottom"
          >
            <i class="fal fa-plus"></i>
            wallet
          </button>
        </div>

        <button
          class="uk-button uk-button-small hfi-button hfi-metamask-button uk-margin-small-left"
          v-if="!isLite && !account"
          @click="openWalletModal"
          :disabled="initialisingWallet"
        >
          <i class="fal fa-wallet uk-margin-small-right"></i>
          {{ connectWalletButtonText }}
        </button>

        <div
          class="uk-navbar-item uk-padding-remove-horizontal uk-margin-small-left hfi-navbar-item"
          v-if="!isLite && account && network && explorerMeta"
        >
          <ul class="uk-navbar-nav">
            <li
              class="uk-animation-toggle wallet-dropdown-wrapper"
              tabindex="0"
            >
              <a
                class="uk-button uk-button-small hfi-button hfi-metamask-button"
              >
                <i class="fal fa-wallet uk-margin-small-right"></i
                >{{ account.slice(0, 6) }}...{{ account.slice(-4) }}
              </a>

              <div
                class="uk-navbar-dropdown uk-flex-center"
                uk-dropdown="mode: click; offset: -2; boundary-align: true; boundary: .wallet-dropdown-wrapper;"
              >
                <CurrentNetwork />
                <div class="uk-margin-xsmall-top">
                  {{ account.slice(0, 10) }}...{{ account.slice(-6) }}
                </div>
                <div class="uk-width-1-1">
                  <a
                    @click="copyToClipboard('header-account')"
                    uk-tooltip="title: copy address to clipboard; pos: bottom;"
                    ><i class="fal fa-copy uk-margin-small-right"></i
                  ></a>
                  <a
                    :href="
                      explorerMeta && explorerMeta.typeUrl
                        ? explorerMeta.typeUrl
                        : '#'
                    "
                    target="_blank"
                    :uk-tooltip="`title: ${explorerMeta.typeMessage}; pos: bottom;`"
                    ><i class="fal fa-external-link-square"></i
                  ></a>
                  <input
                    :value="account"
                    id="header-account"
                    readonly
                    class="off-screen"
                  />
                </div>
                <button
                  class="uk-button uk-button-xsmall hfi-button uk-width-expand uk-margin-small-top"
                  @click="disconnectWallet"
                >
                  {{ isLite ? "sign out" : "disconnect" }}
                </button>
              </div>
            </li>
          </ul>
        </div>
      </div>

      <div
        id="sidenav"
        uk-offcanvas="flip: false; overlay: true; container: body;"
        class="uk-offcanvas"
      >
        <div class="uk-offcanvas-bar">
          <ul class="uk-nav">
            <li
              class="uk-offcanvas-close uk-position-relative uk-position-left"
            >
              <img
                width="40"
                uk-svg
                src="../assets/images/handle.fiLogoCutInverted.svg"
                class="uk-margin-small-right"
              />
              <span class="uk-margin-small-left uk-visible@s"> handle.fi </span>
            </li>
            <li
              class="uk-offcanvas-close uk-position-relative uk-position-left"
            >
              <router-link to="/">dashboard</router-link>
            </li>
            <li class="uk-parent hfi-parent">
              fxTokens
              <ul class="uk-nav-sub">
                <li
                  class="uk-offcanvas-close uk-position-relative uk-position-left"
                >
                  <router-link to="/borrow">borrow</router-link>
                </li>
                <li
                  class="uk-offcanvas-close uk-position-relative uk-position-left"
                >
                  <router-link to="/repay">repay</router-link>
                </li>
              </ul>
            </li>

            <li
              class="uk-offcanvas-close uk-position-relative uk-position-left"
            >
              <router-link to="/earn">earn</router-link>
            </li>
            <li
              class="uk-offcanvas-close uk-position-relative uk-position-left"
            >
              <router-link to="/convert">convert</router-link>
            </li>
            <li
              class="uk-offcanvas-close uk-position-relative uk-position-left"
            >
              <router-link to="/trade">trade</router-link>
            </li>
            <!--<li
              class="uk-offcanvas-close uk-position-relative uk-position-left"
            >
              <router-link to="/redeem">redeem</router-link>
            </li>-->
            <li
              class="uk-offcanvas-close uk-position-relative uk-position-left"
            >
              <router-link to="/bridge">bridge</router-link>
            </li>
          </ul>
        </div>
      </div>

      <div
        id="wallet-dropdown"
        class="uk-flex-top uk-margin-remove-top"
        uk-modal="bgClose: false; container: false;"
      >
        <div
          class="uk-modal-dialog uk-animation-slide-top-medium uk-modal-body uk-margin-auto-vertical uk-padding-small"
        >
          <div v-if="!showEmailLogin">
            <h4 class="uk-margin-small-bottom">connect your wallet</h4>
            <a @click.prevent="closeWalletModal" class="uk-modal-close-default">
              <i class="fal fa-times"></i>
            </a>
            <div uk-grid class="uk-grid-small uk-child-width-1-2">
              <div>
                <button
                  class="uk-button uk-width-expand uk-flex uk-flex-center uk-flex-middle"
                  @click="connectMetamask"
                >
                  <img
                    width="25"
                    height="25"
                    src="/metamaskHandleLogo.svg"
                    class="mm-logo uk-margin-small-right"
                  />
                  metamask
                </button>
              </div>
              <div>
                <button
                  class="uk-button uk-width-expand uk-flex uk-flex-center uk-flex-middle"
                  @click="connectWalletConnect"
                >
                  <img
                    uk-svg
                    width="25"
                    height="25"
                    src="/walletconnect-logo.svg"
                    class="uk-margin-small-right"
                  />
                  walletconnect
                </button>
              </div>
              <!-- <div>
                <button
                  class="uk-button uk-width-expand uk-flex uk-flex-center uk-flex-middle"
                  @click="toggleShowEmailLogin"
                >
                  <img
                    uk-svg
                    width="25"
                    height="25"
                    src="/magic-logo.svg"
                    class="uk-margin-small-right"
                  />
                  email
                </button>
              </div> -->
            </div>
          </div>
          <div v-if="showEmailLogin">
            <h4 class="uk-margin-small-bottom">connect with email</h4>
            <a @click.prevent="openWalletModal" class="uk-modal-close-default">
              <i class="fal fa-times"></i>
            </a>
            <EmailLogin />
          </div>
        </div>
      </div>
    </nav>

    <SettingsModal />

    <div
      v-if="$route.path !== '/convert'"
      id="convert-modal"
      class="uk-flex-top uk-margin-remove-top"
      uk-modal="bgClose: false; container: false; stack: true; sel-close: .hfi-convert-close;"
    >
      <div
        class="uk-modal-dialog uk-modal-body uk-margin-auto-vertical uk-padding-small uk-width-auto uk-padding-xsmall-top"
      >
        <a class="uk-modal-close-default hfi-convert-close">
          <i class="fal fa-times"></i>
        </a>
        <ConvertComponent :modal="true" />
      </div>
    </div>
  </div>
</template>

<script>
import { store } from "@/store";
import {
  getExplorerMeta,
  copyToClipboard,
  getDecimalsAmount,
} from "@/utils/utils";
import {
  connect,
  disconnect,
  loginToMagicWithGoogle,
  addAssetToWallet,
} from "@/utils/wallet";
import UIkit from "uikit";
import Network from "@/types/Network";
import CurrentNetwork from "@/components/CurrentNetwork";
import WalletTypes from "@/types/Wallet";
import EmailLogin from "./EmailLogin.vue";
import SettingsModal from "@/components/SettingsModal";
import ConvertComponent from "@/components/ConvertComponent";
import getContractAddresses from "@/contracts/utils/getContractAddresses";
import { ethers } from "ethers";

export default {
  name: "AppNavBar",
  props: {
    isShop: { type: Boolean, default: false },
  },
  components: {
    EmailLogin,
    CurrentNetwork,
    SettingsModal,
    ConvertComponent,
  },
  data() {
    return {
      copyToClipboard,
      showWalletModal: false,
      explorerMeta: null,
      Network,
      showEmailLogin: false,
      formatEther: (value, digits = 2, tokenDecimals = 18) => {
        if (value == null) return "0.00";
        value = getDecimalsAmount(value, tokenDecimals, 18);
        return parseFloat(ethers.utils.formatEther(value)).toLocaleString(
          undefined,
          this.digits(digits)
        );
      },
    };
  },
  mounted() {
    this.onNetworkChange();
  },
  computed: {
    account() {
      return store.state.account;
    },
    network() {
      return store.state.network;
    },
    tge() {
      return localStorage.getItem("tge") === null
        ? true
        : JSON.parse(localStorage.getItem("tge"));
    },
    isLite() {
      return store.state.isLite;
    },
    hideContent() {
      return store.state.isLite && !this.account;
    },
    initialisingWallet() {
      return store.state.initialisingWallet;
    },
    connectWalletButtonText() {
      return this.initialisingWallet ? "connecting..." : "connect wallet";
    },
    validNetworkForForex() {
      return this.$router.options.routes
        .find((route) => route.path === "/convert")
        .meta.networks.includes(this.network);
    },
    forexBalance() {
      return store.getters.forexBalance;
    },
  },
  watch: {
    network: function (value) {
      this.onNetworkChange();
    },
    account: function (value) {
      this.onNetworkChange();
    },
  },
  methods: {
    async onNetworkChange() {
      if (this.network) {
        this.explorerMeta = await getExplorerMeta(this.account, "address");
      }
    },
    openWalletModal() {
      this.showWalletModal = true;
      this.showEmailLogin = false;
      UIkit.modal("#wallet-dropdown").show();
    },
    closeWalletModal() {
      this.showWalletModal = false;
      this.showEmailLogin = false;
      UIkit.modal("#wallet-dropdown").hide();
    },
    toggleShowEmailLogin() {
      this.showEmailLogin = !this.showEmailLogin;
    },
    connectMetamask() {
      this.connectWallet(WalletTypes.Metamask);
    },
    connectWalletConnect() {
      this.connectWallet(WalletTypes.WalletConnect);
    },
    async connectWallet(provider) {
      await connect(provider);
      this.closeWalletModal();
    },
    async connectMagicGoogle() {
      await loginToMagicWithGoogle();
    },
    disconnectWallet: async function () {
      disconnect();
    },

    async addForexToWallet() {
      const contractAddresses = await getContractAddresses();
      const tokenToAdd = {
        symbol: "FOREX",
        address: contractAddresses.forex,
        decimals: 18,
        image: "https://app.handle.fi/handle.fiForexLogoForAbritrum.png",
      };

      addAssetToWallet(tokenToAdd);
    },

    async convert() {
      UIkit.dropdown(".forex-dropdown").hide();
      if (this.$route.path !== "/convert") UIkit.modal("#convert-modal").show();
    },

    digits(minDigits, maxDigits = minDigits) {
      return {
        minimumFractionDigits: minDigits,
        maximumFractionDigits: maxDigits,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
@use "src/assets/styles/handle.fi.scss" as handle;

#header {
  padding: 8px 15px;
}

#account {
  position: absolute;
  opacity: 0;
  z-index: -1;
}
svg.mm-logo {
  .cls-1 {
    fill: handle.$background;
  }
  .cls-2 {
    fill: #435655;
  }
  .cls-3 {
    fill: #a7e1ae;
  }
  .cls-4 {
    fill: #8ebe97;
  }
  .cls-5 {
    fill: #749b80;
  }
  .cls-6 {
    fill: #a7dfae;
  }
  .cls-7 {
    fill: #8cbc97;
  }
  .cls-8 {
    fill: #8cbc96;
  }
  .cls-9 {
    fill: #8ab995;
  }
  .cls-10 {
    fill: #8ab894;
  }
}

.underline {
  text-decoration: underline;
}
</style>
