<template>
  <div class="uk-container uk-container-xlarge uk-width-1-1">
    <vue-headful
      description="convert between token pairs"
      image="src/assets/logo.png"
      :title="`${isLite ? 'handleLite' : 'handle.fi'} | convert`"
    />
    <ConvertComponent />
  </div>
</template>

<script>
import ConvertComponent from "@/components/ConvertComponent";
import { store } from "@/store";

export default {
  name: "Convert",
  components: {
    ConvertComponent,
  },
  computed: {
    isLite() {
      return store.state.isLite;
    },
  },
};
</script>

<style lang="scss" scoped>
@use "../assets/styles/handle.fi" as handle;
</style>
