<template>
  <div class="uk-container uk-flex uk-flex-middle">
    <vue-headful
      description="handleLite"
      image="src/assets/logo.png"
      title="handle.fi"
    />

    <div v-if="initialisingWallet" class="uk-text-center margin-small-top">
      <vue-loaders-ball-pulse color="currentColor" scale=".6" />
      <p>securely initialising wallet...</p>
    </div>

    <div v-else uk-grid>
      <div class="uk-width-2-3@m uk-text-center uk-text-left@m">
        <h2 class="uk-h2">handleLite</h2>
        <h4 class="uk-h4 uk-margin-remove-top">the global defi FX protocol</h4>
        <p>
          create and trade multi-currency stablecoins backed by ethereum and
          more
        </p>
      </div>

      <div class="uk-width-1-3@m uk-flex uk-flex-middle uk-flex-center">
        <a
          class="uk-button uk-button uk-button-default uk-padding-small uk-padding-remove-vertical"
          @click="onGoogleLogin"
        >
          sign in with Google
          <img
            uk-svg
            class="uk-preserve uk-margin-xsmall-left"
            src="/googleLogo.svg"
            alt="Google"
            width="20"
            height="20"
          />
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { store } from "@/store";
import EmailLogin from "./EmailLogin.vue";
import { loginToMagicWithGoogle } from "@/utils/wallet.js";

export default {
  name: "LiteLanding",
  components: {
    EmailLogin,
  },
  data() {
    return {};
  },
  computed: {
    initialisingWallet() {
      return store.state.initialisingWallet;
    },
    account() {
      return store.state.account;
    },
    isLite() {
      return store.state.isLite;
    },
  },
  methods: {
    onGoogleLogin: async function () {
      await loginToMagicWithGoogle();
    },
  },
};
</script>
