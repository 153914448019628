<template>
  <div
    :class="`convert uk-flex ${
      modal ? '' : 'uk-child-width-expand'
    } uk-flex-wrap uk-grid-medium`"
    uk-grid
  >
    <div class="uk-flex uk-flex-center uk-flex-right@m">
      <form novalidate autocomplete="off" class="uk-form-width-large">
        <fieldset class="uk-fieldset">
          <div class="uk-flex uk-flex-between">
            <h2
              :class="`${
                modal ? 'uk-h4 hfi-modal-title' : 'uk-h2 uk-margin-small-bottom'
              }`"
            >
              convert
            </h2>

            <div
              v-if="canUseTransformer"
              class="uk-flex uk-flex-bottom"
              style="margin-bottom: 14px"
            >
              <h5
                :class="`uk-margin-remove-bottom ${
                  !canUseTransformer
                    ? 'disabled-opacity cursor-default'
                    : 'cursor-pointer'
                }`"
              >
                route
              </h5>
              <div class="hfi-convert-checkbox uk-margin-small-left">
                <input
                  type="checkbox"
                  name="useTransformer"
                  id="use-transformer"
                  :checked="useTransformer"
                  :disabled="!network || !account || !canUseTransformer"
                  @change="toggleUseTransformer"
                />
                <label
                  for="use-transformer"
                  :uk-tooltip="
                    !network || !account
                      ? undefined
                      : `title: ${
                          useTransformer ? 'internal' : 'external'
                        } route selected; pos: bottom;`
                  "
                />
              </div>
            </div>
          </div>

          <div>
            <div>
              <label
                class="uk-form-label uk-flex uk-width-expand uk-flex-between"
                for="fromFxToken"
              >
                from token
              </label>

              <TokenSelect
                id="fromFxToken"
                :key="'fromFxToken_' + (fromToken ? fromToken.symbol : 'from')"
                class="fromFxToken-boundary"
                boundaryClass="fromFxToken-boundary"
                selectClasses="uk-width-expand"
                inputClasses="uk-text-left"
                :tokens="fromTokens"
                :value="fromToken ? fromToken.symbol : Token.ETH"
                @change="(option) => onSelectToken('fromToken', option)"
                @newToken="(token) => newToken('fromToken', token)"
                :disabled="tokenList.length === 0"
                :showETHForWETH="false"
                :search="true"
                :sortOptions="true"
                maxHeight="136"
                :balances="balances"
              />
            </div>

            <div class="uk-margin">
              <label
                class="uk-form-label uk-flex uk-width-expand uk-flex-between"
                for="fromFxToken"
              >
                <span>amount</span>
                <span v-if="fromToken && balances[fromToken.symbol]">
                  bal:
                  {{ displayAmount(fromToken, balances[fromToken.symbol]) }}
                  {{ fromToken.symbol }}
                </span>
              </label>

              <div class="uk-form-controls uk-position-relative uk-flex">
                <div
                  class="uk-position-absolute"
                  style="top: 10px"
                  v-if="loadingTradeData && rateDirection === 'buy'"
                >
                  <vue-loaders-ball-pulse color="currentColor" scale=".5" />
                </div>

                <input
                  :class="`uk-width-expand uk-input${
                    isAmountAboveBalance ? ' hfi-danger' : ''
                  }`"
                  id="fromAmount"
                  :value="fromAmount"
                  :placeholder="loadingTradeData ? '' : 'tokens to sell'"
                  @input="(event) => setAmount('from', event.target.value)"
                  :disabled="!account || converting || disabled"
                />

                <button
                  class="uk-button hfi-button hfi-input-button"
                  @click.prevent="setMaxAmount"
                  :disabled="!account || converting || disabled"
                  v-if="network && rateDirection === 'sell'"
                >
                  max
                </button>

                <button
                  class="uk-button hfi-button hfi-input-button uk-flex-between"
                  @click.prevent="getQuote"
                  :disabled="loadingTradeData || converting"
                  v-if="network && rateDirection === 'buy'"
                  :uk-tooltip="
                    loadingTradeData || converting
                      ? undefined
                      : 'title: click to refresh quote; pos: left;'
                  "
                >
                  <i
                    :class="`fal fa-sync ${
                      secondsUntilNextRateFetch ? '' : 'fa-spin'
                    }`"
                  ></i>
                  <span class="uk-margin-small-left" v-if="!converting">{{
                    this.secondsUntilNextRateFetch || ""
                  }}</span>
                </button>
              </div>
            </div>

            <div class="uk-margin-top uk-width-1-1 uk-flex uk-flex-center">
              <button
                class="uk-icon-button"
                :uk-tooltip="
                  !account || loadingTradeData || converting
                    ? undefined
                    : 'title: switch tokens; pos: right;'
                "
                :disabled="!account || loadingTradeData || converting"
                @click.prevent="swapDirection"
              >
                <i
                  class="fal fa-exchange"
                  style="transform: rotate(90deg); margin-left: -2px"
                ></i>
              </button>
            </div>

            <div class="uk-margin-small-top">
              <label
                class="uk-form-label uk-flex uk-width-expand uk-flex-between"
                for="toFxToken"
              >
                to token
              </label>

              <TokenSelect
                id="toFxToken"
                :key="'toFxToken_' + (fromToken ? fromToken.symbol : 'from')"
                class="toFxToken-boundary"
                boundaryClass="toFxToken-boundary"
                selectClasses="uk-width-expand"
                inputClasses="uk-text-left"
                :tokens="
                  toTokens.filter(
                    (token) => fromToken && token.symbol !== fromToken.symbol
                  )
                "
                :value="toToken ? toToken.symbol : Token.FOREX"
                @change="(option) => onSelectToken('toToken', option)"
                @newToken="(token) => newToken('toToken', token)"
                :disabled="tokenList.length === 0"
                :showETHForWETH="false"
                :search="true"
                :sortOptions="false"
                maxHeight="136"
                :balances="balances"
              />
            </div>

            <div class="uk-margin-top">
              <label
                class="uk-form-label uk-flex uk-width-expand uk-flex-between"
                for="toFxToken"
              >
                <span>amount</span>
                <span v-if="toToken && balances[toToken.symbol]">
                  bal:
                  {{ displayAmount(toToken, balances[toToken.symbol]) }}
                  {{ toToken.symbol }}
                </span>
              </label>

              <div class="uk-form-controls uk-position-relative uk-flex">
                <div
                  class="uk-position-absolute"
                  style="top: 10px"
                  v-if="loadingTradeData && rateDirection === 'sell'"
                >
                  <vue-loaders-ball-pulse color="currentColor" scale=".5" />
                </div>

                <input
                  class="uk-width-expand uk-input"
                  id="toAmount"
                  :value="toAmount"
                  :placeholder="loadingTradeData ? '' : 'tokens to buy'"
                  @input="(event) => setAmount('to', event.target.value)"
                  :disabled="converting || disabled"
                />

                <button
                  v-if="network && rateDirection === 'sell' && canGetQuote"
                  class="uk-button hfi-button hfi-input-button uk-flex-between"
                  @click.prevent="getQuote"
                  :disabled="
                    loadingTradeData ||
                    converting ||
                    !secondsUntilNextRateFetch ||
                    disabled
                  "
                  :uk-tooltip="
                    loadingTradeData ||
                    converting ||
                    !secondsUntilNextRateFetch ||
                    disabled
                      ? undefined
                      : 'title: refresh quote; pos: left;'
                  "
                >
                  <i
                    :class="`fal fa-sync ${loadingTradeData ? 'fa-spin' : ''}`"
                  ></i>
                  <span class="uk-margin-small-left" v-if="!converting">
                    {{ secondsUntilNextRateFetch || "" }}
                  </span>
                </button>
              </div>
            </div>

            <div class="uk-margin-small-top uk-flex uk-flex-between">
              <span>price</span>
              <div class="hfi-swap-button uk-text-right">
                <button
                  uk-tooltip="title: click to reverse; pos: left;"
                  @click.prevent="onReversePrice"
                  style="
                    background: none;
                    border: none;
                    font-size: 16px;
                    cursor: pointer;
                    padding: 0;
                  "
                >
                  <span v-html="displayPrice"></span>
                  <i v-if="displayPrice"></i>
                </button>
              </div>
            </div>

            <div class="uk-flex uk-flex-between uk-flex-middle">
              <span>{{ gasCostLabel }}</span>
              <div style="text-align: right">
                <span class="">
                  {{ estimatedTradeCost }}
                </span>
              </div>
            </div>

            <div class="uk-margin-small-top">
              <button
                id="button"
                :style="`text-transform: none; ${
                  disabled ? 'opacity: 1 !important' : ''
                }`"
                :class="`uk-button uk-button-primary uk-width-expand hfi-button ${
                  isAmountAboveBalance
                    ? 'hfi-danger'
                    : disabled
                    ? 'hfi-warning'
                    : ''
                }`"
                type="button"
                :disabled="!validTrade || disabled"
                @click.prevent="onPrimaryButtonPress"
              >
                <i v-if="disabled" class="fal fa-exclamation-triangle" />
                {{ primaryButtonText }}
                <vue-loaders-ball-pulse
                  style="margin-bottom: -5px"
                  color="currentColor"
                  scale="0.5"
                  v-if="this.converting || this.settingTokenAllowance"
                />
              </button>
            </div>
          </div>
        </fieldset>
      </form>
    </div>

    <div v-if="!modal && $vssWidth >= 744" class="uk-flex uk-flex-left">
      <PriceChart
        id="convertChart"
        :pricesNetwork="defaultNetworkForPrices"
        :fromToken="fromToken"
        :toToken="toToken"
        style="z-index: 1"
        class="uk-form-width-large"
      />
    </div>

    <div
      id="confirm-modal"
      v-if="validTrade"
      class="uk-flex-top uk-margin-remove-top"
      uk-modal="bgClose: false; container: false; stack: true"
    >
      <div
        class="uk-modal-dialog uk-animation-slide-top-medium uk-modal-body uk-margin-auto-vertical uk-padding-small"
        style="max-width: 500px"
      >
        <div
          class="uk-flex uk-flex-between uk-margin-xsmall-bottom"
          style="margin-right: 32px; margin-top: -4px"
        >
          <h4 class="uk-margin-remove-bottom">confirm details</h4>
          <button
            :class="`uk-button hfi-button hfi-modal-button uk-flex-between uk-margin-small-left ${
              loadingTradeData || converting || !secondsUntilNextRateFetch
                ? 'cursor-default'
                : ''
            }`"
            @click.prevent="getQuote"
            :disabled="
              loadingTradeData || converting || !secondsUntilNextRateFetch
            "
            :uk-tooltip="
              loadingTradeData || converting || !secondsUntilNextRateFetch
                ? undefined
                : 'title: refresh quote; pos: left;'
            "
          >
            <i
              :class="`fal fa-sync ${
                secondsUntilNextRateFetch ? '' : 'fa-spin'
              }`"
            ></i>
            <span class="uk-margin-small-left">
              {{ secondsUntilNextRateFetch || "" }}
            </span>
          </button>
        </div>

        <a @click.prevent="closeConfirmModal" class="uk-modal-close-default">
          <i class="fal fa-times"></i>
        </a>

        <div>
          <div class="uk-margin-small-top uk-flex uk-flex-between">
            <span>you pay</span>
            <div style="uk-text-right">
              <span>
                {{ displayFromAmount }}
                {{ fromToken.symbol }}
                <img
                  style="margin-top: -5px"
                  width="24"
                  :src="
                    tokenList.find((tok) => tok.symbol === fromToken.symbol)
                      ? tokenList.find((tok) => tok.symbol === fromToken.symbol)
                          .icon
                      : 'handle.fiTokenPlaceholder.png'
                  "
                  :alt="fromToken.symbol"
                  @error="iconError"
                />
              </span>
            </div>
          </div>

          <div class="uk-margin-small-top uk-flex uk-flex-between">
            <span>you receive</span>
            <div style="uk-text-right">
              <span>
                {{ displayToAmount }}
                {{ toToken.symbol }}
                <img
                  style="margin-top: -5px"
                  width="24"
                  :src="
                    tokenList.find((tok) => tok.symbol === toToken.symbol)
                      ? tokenList.find((tok) => tok.symbol === toToken.symbol)
                          .icon
                      : 'handle.fiTokenPlaceholder.png'
                  "
                  :alt="toToken.symbol"
                  @error="iconError"
                />
              </span>
            </div>
          </div>

          <div class="uk-margin-small-top uk-flex uk-flex-between">
            <span>price</span>
            <div class="uk-text-right hfi-swap-button">
              <button
                uk-tooltip="title: click to reverse; pos: left;"
                @click.prevent="onReversePrice"
                style="
                  background: none;
                  border: none;
                  font-size: 16px;
                  cursor: pointer;
                  padding: 0;
                "
              >
                <span v-html="displayPrice"></span>
                <i v-if="displayPrice"></i>
              </button>
            </div>
          </div>

          <div class="uk-margin-small-top uk-flex uk-flex-between">
            <span>{{ gasCostLabel }}</span>
            <div style="text-align: right">
              <span class="">{{ estimatedTradeCost }}</span>
            </div>
          </div>
        </div>

        <div class="uk-margin">
          <button
            id="convertButton"
            class="uk-button uk-button-primary uk-width-expand hfi-button"
            type="button"
            :disabled="loadingTradeData || converting"
            @click="convert"
          >
            <vue-loaders-ball-pulse
              v-if="loadingTradeData || converting || !tradeData"
              color="currentColor"
              scale="0.5"
              style="margin-bottom: -6px"
            />
            <span v-else>convert</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Token from "@/types/Token";
import { store } from "@/store";
import createErc20 from "@/contracts/ERC20/createInstance";
import { signer, provider } from "@/utils/wallet";
import sendConvertTransaction from "@/contracts/utils/sendConvertTransaction";
import { ethers } from "ethers";
import {
  showNotification,
  closeAllNotifications,
  isNativeToken,
  getDecimalsAmount,
} from "@/utils/utils";
import { getUSDPrice } from "@/utils/prices";
import UIkit from "uikit";
import TokenSelect from "@/components/TokenSelect";
import Network from "@/types/Network";
import PriceChart from "@/components/PriceChart";
import VueScreenSize from "vue-screen-size";

const HIGHLIGHTED_TOKENS = [
  Token.FOREX,
  Token.DAI,
  Token.ETH,
  Token.EURS,
  Token.LUSD,
  Token.MATIC,
  Token.sUSD,
  Token.USDC,
  Token.USDT,
  Token.WBTC,
  Token.WETH,
  Token.EURT,
  Token.sEUR,
];

const getNativeToken = (network) => {
  if (network === Network.polygon) {
    return Token.MATIC;
  }

  return Token.ETH;
};

const getDefaultTokenSymbolsForNetwork = (network) => {
  if (network === Network.polygon) {
    return [getNativeToken(network), Token.USDC];
  }

  return [getNativeToken(network), Token.FOREX];
};

const tokenStringAmountToBigNumber = (token, stringNumber) => {
  if (stringNumber === "") {
    return ethers.BigNumber.from(0);
  }
  return ethers.utils.parseUnits(
    stringNumber.replaceAll(",", ""),
    token.decimals
  );
};

let secondsUntilNextRateFetchInterval = undefined;
let getQuoteDelayTimeout = undefined;

export default {
  name: "ConvertComponent",
  props: {
    modal: { type: Boolean, default: false },
  },
  components: {
    TokenSelect,
    PriceChart,
  },
  mixins: [VueScreenSize.VueScreenSizeMixin],
  data() {
    return {
      fromToken: {},
      toToken: {},
      fromAmount: "",
      toAmount: "",
      rate: ethers.BigNumber.from(0),
      allowances: {},
      rateDirection: "sell",
      loadingTradeData: false,
      converting: false,
      settingTokenAllowance: false,
      tradeData: undefined,
      secondsUntilNextRateFetch: undefined,
      walletUpdateEventId: undefined,
      baseAssetPrices: {},
      intendedNetwork: process.env.VUE_APP_NETWORK,
      reversePrice: false,
      isConfirmModalOpen: false,
      Token,
      validNetworksForTokenPrices: [
        Network.homestead,
        Network.polygon,
        Network.arbitrum,
      ],
      slippage: "0",
      gasPriceOption: "fast",
      gasPriceInGwei: "0",
      useTransformer: false,
      disabledNetworks: [],
    };
  },
  mounted() {
    this.onNetworkChange();
    this.setDefaultToAndFromTokens();
  },
  computed: {
    network() {
      return store.state.network;
    },
    networkDisplay() {
      return store.getters.networkDisplayName;
    },
    account() {
      return store.state.account;
    },
    handleSDK() {
      return store.state.refHandleSDK.get();
    },
    transformer() {
      const sdk = this.handleSDK;
      if (sdk == null) return null;
      return sdk.contracts.fxTransformer;
    },
    canUseTransformer() {
      if (!this.fromToken?.symbol || !this.toToken?.symbol) return false;
      const isFxToken = (symbol) =>
        // SDK might not be loaded here yet so it is not used to verify
        // whether the token is an fxToken.
        symbol.startsWith("fx") && symbol.length === 5;
      const canUseTransformer =
        isFxToken(this.fromToken.symbol) && isFxToken(this.toToken.symbol);
      if (!canUseTransformer) this.useTransformer = false;
      return canUseTransformer;
    },
    convertSDK() {
      return store.state.refConvertSDK.get();
    },
    globalSlippage() {
      return store.state.globalSlippage || "0.5";
    },
    globalGasPriceOption() {
      return store.state.globalGasPriceOption || "fast";
    },
    globalGasPriceInGwei() {
      return store.state.globalGasPriceInGwei || "0";
    },
    stateFromToken() {
      return store.state.fromToken;
    },
    stateToToken() {
      return store.state.toToken;
    },
    balances() {
      return store.state.balances;
    },
    baseAsset() {
      return store.state.network === Network.polygon ? Token.MATIC : Token.ETH;
    },
    isNativeTokenFrom() {
      return isNativeToken(this.fromToken.symbol);
    },
    isLite() {
      return store.state.isLite;
    },
    fromTokens() {
      const tokensWithBalance = this.tokenList.filter(
        (t) => this.balances[t.symbol] && this.balances[t.symbol].gt(0)
      );

      return tokensWithBalance.length
        ? this.tokenList.map((t) => ({
            ...t,
            highlight: this.balances[t.symbol] && this.balances[t.symbol].gt(0),
          }))
        : this.tokenList;
    },
    toTokens() {
      return [
        ...this.tokenList
          .filter((tok) => tok.highlight)
          .sort((a, b) => (a.highlightPos > b.highlightPos ? 1 : -1)),
        ...this.tokenList.filter((tok) => !tok.highlight),
      ];
    },
    isAmountAboveBalance() {
      if (!this.fromToken) {
        return false;
      }

      return (
        this.balances[this.fromToken.symbol] &&
        tokenStringAmountToBigNumber(this.fromToken, this.fromAmount).gt(
          this.balances[this.fromToken.symbol]
        )
      );
    },
    defaultNetworkForPrices() {
      return this.validNetworksForTokenPrices.includes(store.state.network)
        ? store.state.network
        : Network.homestead;
    },
    gasCostLabel() {
      return this.isLite ? "est. network fee" : "est. gas cost";
    },
    canGetQuote() {
      return (
        this.fromToken !== this.toToken &&
        ((this.rateDirection === "sell" &&
          this.fromAmount !== "" &&
          tokenStringAmountToBigNumber(this.fromToken, this.fromAmount).gt(
            0
          )) ||
          (this.rateDirection === "buy" &&
            this.toAmount !== "" &&
            tokenStringAmountToBigNumber(this.fromToken, this.toAmount).gt(0)))
      );
    },
    validTrade() {
      return (
        !this.loadingTradeData &&
        !this.converting &&
        !this.isAmountAboveBalance &&
        this.canGetQuote &&
        !!store.state.account &&
        this.tradeData
      );
    },
    primaryButtonText() {
      if (!this.account) {
        return "please connect wallet";
      }

      if (this.disabled) {
        return `temporarily disabled on ${this.networkDisplay}`;
      }

      if (this.converting || this.settingTokenAllowance) {
        return "";
      }

      if (this.isAmountAboveBalance) {
        return "insufficient balance";
      }

      if (this.validTrade && !this.isTokenAllowanceEnough) {
        return `allow handle to trade ${this.fromToken.symbol}`;
      }

      return "review";
    },
    displayPrice() {
      if (!this.tradeData || !this.fromToken || !this.toToken) {
        return "";
      }

      const fromParsed = getDecimalsAmount(
        ethers.BigNumber.from(this.tradeData.buyAmount),
        this.rateDirection === "buy" && this.network === Network.arbitrum
          ? this.fromToken.decimals
          : this.toToken.decimals,
        this.rateDirection === "buy" && this.network === Network.arbitrum
          ? this.toToken.decimals
          : this.fromToken.decimals
      );

      const price = fromParsed
        .mul(ethers.constants.WeiPerEther)
        .div(this.tradeData.sellAmount);

      const priceToShow = this.reversePrice
        ? ethers.utils
            .parseEther("1")
            .mul(ethers.utils.parseEther("1"))
            .div(price)
        : price;

      const priceString = ethers.utils.formatUnits(priceToShow, 18);

      const displayDecimalPlaces = this.reversePrice
        ? this.rateDirection === "buy" && this.network === Network.arbitrum
          ? this.fromToken.displayDecimals
          : this.toToken.displayDecimals
        : this.rateDirection === "buy" && this.network === Network.arbitrum
        ? this.toToken.displayDecimals
        : this.fromToken.displayDecimals;

      const priceToDisplayDecimals = this.formatPrice(
        priceString,
        displayDecimalPlaces
      );

      return `1 ${
        this.reversePrice
          ? this.rateDirection === "buy" && this.network === Network.arbitrum
            ? this.fromToken.symbol
            : this.toToken.symbol
          : this.rateDirection === "buy" && this.network === Network.arbitrum
          ? this.toToken.symbol
          : this.fromToken.symbol
      } <i class='fal fa-exchange'></i> ${priceToDisplayDecimals} ${
        this.reversePrice
          ? this.rateDirection === "buy" && this.network === Network.arbitrum
            ? this.toToken.symbol
            : this.fromToken.symbol
          : this.rateDirection === "buy" && this.network === Network.arbitrum
          ? this.fromToken.symbol
          : this.toToken.symbol
      }`;
    },

    displayFromAmount() {
      if (!this.tradeData || !this.fromToken || !this.fromAmount) {
        return "";
      }

      return this.formatPrice(this.fromAmount, this.fromToken.displayDecimals);
    },

    displayFromAmountWidth() {
      return this.$refs.displayFromAmount
        ? this.$refs.displayFromAmount.offsetWidth
        : 0;
    },

    displayToAmount() {
      if (!this.tradeData || !this.toToken || !this.toAmount) {
        return "";
      }

      return this.formatPrice(this.toAmount, this.toToken.displayDecimals);
    },

    displayToAmountEl() {
      return this.$refs.displayToAmount
        ? this.$refs.displayToAmount.offsetWidth
        : 0;
    },

    estimatedTradeCost() {
      const price = this.baseAssetPrices[this.baseAsset];

      if (!this.account || !this.tradeData || !price) {
        return "";
      }

      if (this.gasPriceInGwei == "0") this.getGasPrice();

      const gasPrice = ethers.utils.parseUnits(this.gasPriceInGwei, "gwei");

      const cost = ethers.utils.formatEther(gasPrice.mul(this.tradeData.gas));

      const usdCost = parseFloat(cost) * price;

      return `${cost} ${this.baseAsset} (${usdCost.toFixed(2)} USD)`;
    },
    isTokenAllowanceEnough() {
      return (
        (this.canUseTransformer && this.useTransformer) ||
        isNativeToken(this.fromToken.symbol) ||
        (this.allowances[this.fromToken.symbol] &&
          tokenStringAmountToBigNumber(this.fromToken, this.fromAmount).lte(
            this.allowances[this.fromToken.symbol]
          ))
      );
    },
    tokenList() {
      return store.state.tokenList.map((t) => {
        return {
          symbol: t.symbol,
          name: t.name,
          address: t.address,
          icon: t.icon,
          decimals: t.decimals,
          displayDecimals: t.displayDecimals,
          highlight: HIGHLIGHTED_TOKENS.includes(t.symbol),
          highlightPos: HIGHLIGHTED_TOKENS.includes(t.symbol)
            ? HIGHLIGHTED_TOKENS.indexOf(t.symbol)
            : HIGHLIGHTED_TOKENS.length,
        };
      });
    },
    disabled() {
      const disabledNetworks =
        this.canUseTransformer && this.useTransformer
          ? []
          : this.disabledNetworks;

      return disabledNetworks.includes(store.state.network);
    },
  },
  watch: {
    secondsUntilNextRateFetch: {
      handler(value) {
        if (secondsUntilNextRateFetchInterval) {
          clearInterval(secondsUntilNextRateFetchInterval);
        }
        if (value && value > 0) {
          secondsUntilNextRateFetchInterval = setTimeout(() => {
            this.secondsUntilNextRateFetch--;
          }, 1000);
        }

        if (value === 0 && !this.converting) this.getQuote();
      },
      immediate: true,
    },
    network: function () {
      this.onNetworkChange();
    },
    account: function () {
      this.onAccountChange();
    },
    globalSlippage() {
      this.slippage = this.globalSlippage || "0.5";
      if (this.fromAmount) this.getQuote();
    },
    globalGasPriceOption() {
      this.gasPriceOption = this.globalGasPriceOption || "fast";
      if (this.fromAmount) this.getQuote();
    },
    globalGasPriceInGwei() {
      this.gasPriceInGwei =
        this.gasPriceOption === "custom" ? this.globalGasPriceInGwei : "0";
      if (this.fromAmount) this.getQuote();
    },
    tokenList() {
      this.updateBalances(this.tokenList);
      this.setDefaultToAndFromTokens();
    },
  },
  methods: {
    async onAccountChange() {
      this.allowances = {};
      this.slippage = this.globalSlippage || "0.5";
      this.gasPriceOption = this.globalGasPriceOption || "fast";
      this.gasPriceInGwei =
        this.gasPriceOption === "custom" ? this.globalGasPriceInGwei : "0";

      if (this.account) {
        this.updateBalances(this.tokenList);

        await this.setBaseAssetPrice();
        await store.dispatch("updateTokenList");
        if (this.gasPriceInGwei == "0") await this.getGasPrice();
      }
    },
    async onNetworkChange() {
      this.fromAmount = "";
      this.toAmount = "";
      this.allowances = {};
      this.slippage = this.globalSlippage || "0.5";
      this.gasPriceOption = this.globalGasPriceOption || "fast";
      this.gasPriceInGwei =
        this.gasPriceOption === "custom" ? this.globalGasPriceInGwei : "0";
      this.closeConfirmModal();

      if (this.account) {
        this.updateBalances(this.tokenList);
        await this.setBaseAssetPrice();
        await store.dispatch("updateTokenList");
        if (this.gasPriceInGwei == "0") await this.getGasPrice();
      }
    },
    setDefaultToAndFromTokens: async function () {
      if (!this.tokenList.length) {
        return;
      }

      let [fromTokenSymbol, toTokenSymbol] = getDefaultTokenSymbolsForNetwork(
        this.defaultNetworkForPrices
      );

      if (this.stateFromToken) {
        toTokenSymbol =
          toTokenSymbol === this.stateFromToken
            ? fromTokenSymbol
            : toTokenSymbol;
        fromTokenSymbol = this.stateFromToken;
      }

      this.fromToken = this.tokenList.find((t) => t.symbol === fromTokenSymbol);
      this.toToken = this.tokenList.find((t) => t.symbol === toTokenSymbol);
    },
    setBaseAssetPrice: async function () {
      this.baseAssetPrices = {
        ...this.baseAssetPrices,
        [this.baseAsset]: await getUSDPrice(this.baseAsset),
      };
    },
    setUnlimitedTokenAllowance: async function () {
      closeAllNotifications();
      this.settingTokenAllowance = true;

      const fromContract = createErc20(signer, this.fromToken.address);

      closeAllNotifications();
      if (this.isLite) {
        showNotification("success", `submitting transaction...`, undefined, 0);
      } else {
        showNotification(
          "warning",
          `waiting for token allowance approval...`,
          undefined,
          0
        );
      }

      try {
        const approvalTransaction = await fromContract.approve(
          this.tradeData.allowanceTarget,
          ethers.constants.MaxUint256
        );

        closeAllNotifications();
        showNotification(
          "success",
          `allowance transaction processing, please wait...`,
          undefined,
          0
        );

        await approvalTransaction.wait(2);

        await this.setAllowance();

        closeAllNotifications();
        showNotification("success", `token approval successful`);
      } catch (error) {
        console.error(error);

        closeAllNotifications();
        showNotification("danger", `failed to get token approval`);
      }

      this.settingTokenAllowance = false;
    },
    onPrimaryButtonPress: async function () {
      if (this.isTokenAllowanceEnough) {
        this.openConfirmModal();
      } else {
        await this.setUnlimitedTokenAllowance();
      }
    },

    openConfirmModal() {
      this.closeConfirmModal();
      this.isConfirmModalOpen = true;
      if (!this.useTransformer) this.getSwap(false);
      UIkit.modal("#confirm-modal").show();
    },
    closeConfirmModal() {
      this.isConfirmModalOpen = false;
      if (UIkit.modal("#confirm-modal")) UIkit.modal("#confirm-modal").hide();
    },

    async getGasPrice() {
      if (!this.account || !provider) {
        this.gasPriceInGwei = "0";
        return {
          fast: "0",
          fastest: "0",
        };
      }

      const gasPrice =
        this.gasPriceOption === "custom"
          ? this.gasPriceInGwei
          : await provider.getGasPrice();
      const gasPriceInGwei = Number(ethers.utils.formatUnits(gasPrice, "gwei"));

      this.gasPriceLevelsInGwei = {
        fast: (gasPriceInGwei * 1.2).toFixed(1),
        fastest: (gasPriceInGwei * 1.3).toFixed(1),
      };

      if (this.gasPriceOption !== "custom")
        this.gasPriceInGwei = this.gasPriceLevelsInGwei[
          this.gasPriceOption === "very fast" ? "fastest" : this.gasPriceOption
        ];
    },

    displayAmount(token, amount) {
      const correctPrecision = ethers.utils.formatUnits(amount, token.decimals);

      return this.formatPrice(correctPrecision, token.displayDecimals);
    },

    onReversePrice: function () {
      this.reversePrice = !this.reversePrice;
    },

    toggleUseTransformer() {
      this.useTransformer = !this.useTransformer;
      if (this.fromAmount) this.getQuote();
    },

    getQuoteTransformer: async function () {
      this.onStartFetchingTradingData();
      this.reversePrice = false;
      if (!this.canGetQuote) {
        return;
      }
      if (this.rateDirection === "sell") {
        this.toAmount = "";
      } else {
        this.fromAmount = "";
      }
      this.loadingTradeData = true;
      this.tradeData = undefined;

      while (this.handleSDK == null) {
        // SDK hasn't loaded yet, wait until it is loaded.
        await new Promise((r) => setTimeout(r, 500));
      }

      try {
        const fromEthPrice = this.handleSDK.protocol.getFxTokenBySymbol(
          this.fromToken.symbol
        ).rate;
        const toEthPrice = this.handleSDK.protocol.getFxTokenBySymbol(
          this.toToken.symbol
        ).rate;
        const feePerMille = await this.handleSDK.contracts.fxTransformer.transformFeePerMille();
        const feeDiff = ethers.BigNumber.from(1000).sub(feePerMille);
        const rate = fromEthPrice
          .mul(ethers.constants.WeiPerEther)
          .mul(feeDiff)
          .div(toEthPrice)
          .div(1000);

        this.tradeData = {
          buyAmount: ethers.utils.parseEther(this.toAmount || "0").toString(),
          sellAmount: ethers.utils
            .parseEther(this.fromAmount || "0")
            .toString(),
          gas: ethers.utils.parseUnits(this.gasPriceInGwei, "mwei").toString(),
          allowanceTarget: "0",
          // Needed for view reasons during confirm modal, not used otherwise
          data: true,
        };

        if (this.rateDirection === "sell") {
          const fromAmount = ethers.utils.parseEther(this.fromAmount);
          this.tradeData.buyAmount = fromAmount
            .mul(rate)
            .div(ethers.constants.WeiPerEther)
            .toString();
          this.toAmount = this.displayAmount(
            this.toToken,
            this.tradeData.buyAmount,
            this.slippage
          );
        } else {
          const toAmount = ethers.utils.parseEther(this.toAmount);
          this.tradeData.sellAmount = toAmount
            .mul(ethers.constants.WeiPerEther)
            .div(rate)
            .toString();
          this.fromAmount = this.displayAmount(
            this.fromToken,
            this.tradeData.sellAmount
          );
        }
      } catch (error) {
        console.error(error);

        closeAllNotifications();
        showNotification(
          "danger",
          `failed fetching price data, please retry...`
        );
      }

      this.onFinishFetchingTradingData();
    },

    getQuote: async function () {
      if (this.useTransformer) return await this.getQuoteTransformer();
      if (this.isConfirmModalOpen) return await this.getSwap(false);

      this.onStartFetchingTradingData();
      this.reversePrice = false;
      if (!this.canGetQuote) {
        return;
      }

      if (this.rateDirection === "sell") {
        this.toAmount = "";
      } else {
        this.fromAmount = "";
      }

      try {
        this.loadingTradeData = true;
        this.tradeData = undefined;

        const amount = tokenStringAmountToBigNumber(
          this.rateDirection === "sell" ? this.fromToken : this.toToken,
          this.rateDirection === "sell" ? this.fromAmount : this.toAmount
        );

        const gasPriceInWei = ethers.utils
          .parseUnits(this.gasPriceInGwei, "gwei")
          .toString();

        this.tradeData =
          this.rateDirection === "sell"
            ? await this.convertSDK.getQuote(
                this.fromToken.address,
                this.toToken.address,
                amount,
                undefined,
                gasPriceInWei,
                this.account
              )
            : await this.convertSDK.getQuote(
                this.fromToken.address,
                this.toToken.address,
                undefined,
                amount,
                gasPriceInWei,
                this.account
              );

        await this.setAllowance();

        if (this.rateDirection === "sell") {
          this.toAmount = this.displayAmount(
            this.toToken,
            this.tradeData.buyAmount,
            this.slippage
          );
        } else {
          this.fromAmount = this.displayAmount(
            this.fromToken,
            this.tradeData.sellAmount
          );
        }
      } catch (error) {
        console.error(error);

        closeAllNotifications();
        showNotification("danger", `failed fetching price data...`);
      }
      this.onFinishFetchingTradingData();
    },
    getSwap: async function (clearTradeData) {
      this.onStartFetchingTradingData(clearTradeData);

      const amount = tokenStringAmountToBigNumber(
        this.rateDirection === "sell" ? this.fromToken : this.toToken,
        this.rateDirection === "sell" ? this.fromAmount : this.toAmount
      );

      const gasPriceInWei = ethers.utils
        .parseUnits(this.gasPriceInGwei, "gwei")
        .toString();

      try {
        this.tradeData =
          this.rateDirection === "sell"
            ? await this.convertSDK.getSwap(
                this.fromToken.address,
                this.toToken.address,
                amount,
                undefined,
                this.slippage,
                gasPriceInWei,
                this.account
              )
            : await this.convertSDK.getSwap(
                this.fromToken.address,
                this.toToken.address,
                undefined,
                amount,
                this.slippage,
                gasPriceInWei,
                this.account
              );
      } catch (e) {
        console.log("Swap error:", e.description);
      }

      this.onFinishFetchingTradingData();
    },
    onStartFetchingTradingData: function (clearTradeData = true) {
      if (clearTradeData) {
        this.tradeData = undefined;
      }
      this.secondsUntilNextRateFetch = undefined;
    },

    onFinishFetchingTradingData: function () {
      this.loadingTradeData = false;
      this.secondsUntilNextRateFetch = 30;
    },
    getQuoteWithDelay: function () {
      if (getQuoteDelayTimeout) {
        clearTimeout(getQuoteDelayTimeout);
      }

      getQuoteDelayTimeout = setTimeout(() => {
        this.getQuote();
      }, 500);
    },
    getTransformTx: async function () {
      return this.handleSDK.contracts.fxTransformer.populateTransaction.transform(
        this.fromToken.address,
        this.toToken.address,
        ethers.utils.parseEther(this.fromAmount),
        Math.floor(Date.now() / 1000) + 5 * 60
      );
    },
    convert: async function () {
      this.converting = true;
      this.closeConfirmModal();

      try {
        const tx = !this.useTransformer
          ? {
              to: this.tradeData.to,
              data: this.tradeData.data,
              value: ethers.BigNumber.from(this.tradeData.value),
              gasLimit: ethers.BigNumber.from(this.tradeData.gas),
              gasPrice: ethers.utils.parseUnits(this.gasPriceInGwei, "gwei"),
            }
          : await this.getTransformTx();

        await sendConvertTransaction(
          tx,
          "waiting for transaction approval...",
          undefined,
          async (_t, explorerMessage) =>
            `successfully converted ${this.displayFromAmount} ${this.fromToken.symbol} to ${this.displayToAmount} ${this.toToken.symbol}! ${explorerMessage}`
        );

        this.updateBalances([this.fromToken, this.toToken]);
        this.tradeData = undefined;
        this.fromAmount = "";
        this.toAmount = "";
      } catch (error) {
        console.error(error);
        closeAllNotifications();

        this.converting = false;

        closeAllNotifications();
        return showNotification("danger", `token conversion failed.`);
      }

      this.converting = false;
    },
    onSelectToken: async function (fromTo, symbol) {
      closeAllNotifications();
      const oppositeFromTo = fromTo === "fromToken" ? "toToken" : "fromToken";

      if (this[oppositeFromTo].symbol === symbol) {
        this.swapDirection();
        return;
      }

      const token = this.tokenList.find((t) => t.symbol === symbol);

      this[fromTo] = token;

      if (this[fromTo].symbol === this[oppositeFromTo].symbol) {
        this[oppositeFromTo] = this.tokenList.find(
          (t) => t.symbol !== this[fromTo].symbol
        );
      }

      if (this.fromToken.symbol === this.toToken.symbol) {
        if (this.rateDirection === "sell") {
          this.toAmount = "";
        } else {
          this.fromAmount = "";
        }
      }

      this.updateBalances([this.fromToken, this.toToken]);
      await this.getQuote();
    },
    newToken(fromTo, token) {
      this.tokenList.unshift(token);
      this.onSelectToken(fromTo, token.symbol);
    },
    setAmount: function (fromTo, value) {
      this.rateDirection = fromTo == "from" ? "sell" : "buy";
      const amount = fromTo == "from" ? this.fromAmount : this.toAmount;

      if (amount == value) {
        return;
      }

      if (fromTo == "from") {
        this.fromAmount = value;
        this.toAmount = "";
      } else {
        this.toAmount = value;
        this.fromAmount = "";
      }

      this.getQuoteWithDelay();
    },
    updateBalances: async function (tokens) {
      await store.dispatch("updateBalances", tokens);
    },
    setAllowance: async function () {
      if (store.state.account && this.tradeData.allowanceTarget) {
        const allowance = await this.getTokenAllowance(
          this.fromToken,
          this.tradeData.allowanceTarget
        );
        this.allowances = {
          ...this.allowances,
          [this.fromToken.symbol]: allowance,
        };
      }
    },
    getTokenAllowance: async function (token, address) {
      if (isNativeToken(token.symbol)) {
        return;
      }

      const signerAddress = await signer.getAddress();
      try {
        const contract = createErc20(signer, token.address);
        return contract.allowance(signerAddress, address);
      } catch (error) {
        console.error(
          `Failed to get ERC20 allowance for convert. Ensure network "${store.state.network}" is correct.`,
          error
        );
      }
    },
    setMaxAmount: async function () {
      await this.updateBalances([this.fromToken]);
      this.fromAmount = ethers.utils.formatUnits(
        this.balances[this.fromToken.symbol],
        this.fromToken.decimals
      );
      await this.getQuote();

      if (this.isNativeTokenFrom) {
        closeAllNotifications();
        showNotification(
          "warning",
          `warning: ${
            this.fromAmount ? "amount plus" : ""
          } gas cost exceeds balance`,
          undefined,
          0
        );
      }
    },
    swapDirection: function () {
      closeAllNotifications();
      const to = this.toToken;
      this.toToken = this.fromToken;
      this.fromToken = to;

      if (this.rateDirection === "sell") {
        this.fromAmount = this.toAmount;
        this.toAmount = "";
      } else {
        this.toAmount = this.fromAmount;
        this.fromAmount = "";
      }
      this.updateBalances([this.toToken, this.fromToken]);
      this.getQuote();
    },

    formatPrice(v, d) {
      const split = v.replaceAll(",", "").split(".");

      return split[1]
        ? `${parseFloat(split[0]).toLocaleString(
            undefined,
            this.digits(0)
          )}.${split[1].substring(0, d)}`
        : split[0];
    },

    digits(minDigits, maxDigits = minDigits) {
      return {
        minimumFractionDigits: minDigits,
        maximumFractionDigits: maxDigits,
      };
    },
    iconError(event) {
      if (event.target.src.includes("amazonaws"))
        event.target.src = "handle.fiTokenPlaceholder.png";
      else {
        const token = this.tokenList.find(
          (t) =>
            t.symbol ===
            (event.target.alt === Token.ETH ? Token.WETH : event.target.alt)
        );

        if (token) {
          event.target.src =
            "https://token-icons.s3.amazonaws.com/" + token.address + ".png";
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@use "../assets/styles/handle.fi" as handle;

.slippage {
  margin-top: -4px;
}

.hfi-input-button {
  right: 7px;
  &.uk-flex-between {
    justify-content: space-between !important;
  }
}
.uk-icon-button {
  margin: -8px 0 -22px;
}

.hfi-dropdown-button {
  margin-top: 8px;
}

.warning-box {
  border: 2px solid handle.$error-color;
  padding: 10px;
}

.warning {
  color: handle.$error-color;
}

.hfi-modal-button {
  display: flex;
  align-items: center;
  padding: 0;
  width: 3.5rem;
  background-color: handle.$hover;
  cursor: pointer;
  line-height: 1.4rem;
  font-weight: 500;
  color: #f0f0f0;
  border: 1px solid #404040;
  height: 26px;
  padding: 0 6px;
}

.hfi-swap-button {
  color: handle.$green;
  i {
    color: handle.$green;
  }
}
</style>
