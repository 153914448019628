import config from "../../contracts.config.json";
import { store } from "@/store";

/**
 * @returns {Promise<{
 *    comptroller: string,
 *    treasury: string,
 *    vaultLibrary: string,
 *    fxKeeperPool: string,
 *    forex: string,
 *    thirdPartyContracts: {
 *      ETH_USD: string,
 *      AUD_USD: string,
 *      WETH: string,
 *      WBTC: string,
 *      DAI: string,
 *    },
 *    fxTokens: {
 *      fxAUD: string,
 *      fxPHP: string,
 *      fxJPY: string,
 *      fxKRW: string,
 *      fxCNY: string,
 *      fxEUR: string,
 *      fxCHF: string,
 *      fxUSD: string,
 *    },
 *    collaterals: {
 *      WETH: string,
 *      WBTC: string,
 *      DAI: string,
 *      BAT: string,
 *    }
 * }>}
 */
export default (network = store.state.network) => {
  return config[network];
};
