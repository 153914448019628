var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":_vm.id + '-wrapper'}},[_c('div',{class:'hfi-select uk-inline ' + _vm.selectClasses},[(!_vm.disabled)?_c('span',{staticClass:"uk-form-icon uk-form-icon-flip"},[(_vm.open)?_c('i',{staticClass:"fal fa-chevron-up fa-icon",staticStyle:{"font-size":"1.2rem"}}):_c('i',{staticClass:"fal fa-chevron-down fa-icon",staticStyle:{"font-size":"1.2rem"}})]):_vm._e(),(_vm.useFlags)?_c('span',{staticClass:"uk-form-icon"},[_c('gb-flag',{staticClass:"currency-flag",attrs:{"code":_vm.value.slice(0, 2),"size":"small"}})],1):(_vm.useTokenList && _vm.tokenList.length > 0)?_c('span',{staticClass:"uk-form-icon"},[_c('img',{staticStyle:{"margin-right":"2px"},attrs:{"height":"24","width":"24","src":_vm.tokenList.find(
            function (tok) { return tok.symbol ===
              (_vm.shownValue === 'WETH' && _vm.showETHForWETH ? 'ETH' : _vm.shownValue); }
          ).icon,"alt":_vm.shownValue === 'WETH' && _vm.showETHForWETH ? 'ETH' : _vm.shownValue}})]):(_vm.icons.length > 0)?_c('span',{staticClass:"uk-form-icon"},[_c('img',{attrs:{"height":"24","width":"24","src":_vm.icons.find(function (icon) { return icon.toLowerCase().includes(_vm.shownValue.toLowerCase()); }
          ),"alt":_vm.shownValue}})]):_vm._e(),_c('input',{class:'uk-input ' + _vm.inputClasses,attrs:{"disabled":_vm.disabled,"type":"button","value":_vm.values
          ? _vm.values.find(function (val) { return val.value === _vm.shownValue; }).name
          : _vm.shownValue}})]),_c('div',{staticClass:"uk-dropdown uk-width-expand",style:(_vm.id === 'selectCurrency' ? 'width: 108px;' : ''),attrs:{"id":_vm.dropdownId,"uk-dropdown":'mode: click; delay-hide: 0;' +
      ' pos: ' +
      _vm.position +
      ';' +
      (_vm.boundaryClass
        ? (" boundary: ." + _vm.boundaryClass + "; boundary-align: true;")
        : '') +
      " offset: " + _vm.offset + ";"}},[(_vm.search)?_c('div',[_c('form',{staticClass:"uk-search uk-search-default"},[_vm._m(0),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.searchValue),expression:"searchValue"}],ref:"search",staticClass:"uk-input uk-search-input",attrs:{"type":"search","placeholder":"search"},domProps:{"value":(_vm.searchValue)},on:{"input":function($event){if($event.target.composing){ return; }_vm.searchValue=$event.target.value}}}),_c('a',{staticClass:"uk-form-icon uk-form-icon-flip",on:{"click":function($event){$event.preventDefault();return _vm.clearSearch($event)}}},[_c('i',{staticClass:"fal fa-times fa-icon",staticStyle:{"margin-right":"-4px","font-size":"1.2rem"}})])])]):_vm._e(),_c('vue-custom-scrollbar',{staticClass:"scroll-area uk-overflow-auto",style:({ maxHeight: _vm.maxHeight === '' ? 'unset' : _vm.maxHeight + 'px' }),attrs:{"settings":_vm.vueCustomScrollbarSettings}},[_c('ul',{staticClass:"uk-nav uk-dropdown-nav handle-select-currency"},_vm._l((_vm.sortedOptions.filter(function (option) { return option.toLowerCase().includes(_vm.searchValue.toLowerCase()); }
          )),function(option,i){return _c('li',{key:_vm.value + '_' + option + '_' + i,class:option === _vm.value && !_vm.unavailable.some(function (el) { return el === _vm.value; })
              ? 'uk-active'
              : '' + ' uk-width-expand',attrs:{"v-if":option}},[_c('a',{class:(_vm.icons.length > 0 ? 'with-icon' : '') +
              (_vm.unavailable.some(function (el) { return el === option; }) ? ' disabled' : ''),attrs:{"uk-tooltip":_vm.unavailable.some(function (el) { return el === option; })
                ? 'title: coming soon; pos: left;'
                : undefined},on:{"click":function($event){$event.preventDefault();_vm.unavailable.some(function (el) { return el === option; })
                ? undefined
                : _vm.changeValue(option)}}},[(_vm.useFlags)?_c('gb-flag',{staticClass:"currency-flag-dropdown",attrs:{"code":option.slice(0, 2),"size":"small"}}):(_vm.useTokenList && _vm.tokenList.length > 0)?_c('img',{staticStyle:{"margin-right":"2px"},attrs:{"height":"24","width":"24","src":_vm.tokenList.find(
                  function (tok) { return tok.symbol ===
                    (option === 'WETH' && _vm.showETHForWETH ? 'ETH' : option); }
                ).icon,"alt":option === 'WETH' && _vm.showETHForWETH ? 'ETH' : option}}):(_vm.icons.length > 0)?_c('img',{staticStyle:{"margin-right":"2px"},attrs:{"height":"24","width":"24","src":_vm.icons.find(function (icon) { return icon
                    .toLowerCase()
                    .includes(
                      (option === 'WETH' && _vm.showETHForWETH
                        ? 'ETH'
                        : option
                      ).toLowerCase()
                    ); }
                ),"alt":option === 'WETH' && _vm.showETHForWETH ? 'ETH' : option}}):_vm._e(),_vm._v(" "+_vm._s(_vm.values ? _vm.values.find( function (val) { return val.value === (option === "WETH" && _vm.showETHForWETH ? "ETH" : option); } ).name : option === "WETH" && _vm.showETHForWETH ? "ETH" : option)+" ")],1)])}),0)])],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"uk-search-icon-flip"},[_c('i',{staticClass:"fal fa-search uk-margin-small-left"})])}]

export { render, staticRenderFns }