<template>
  <div>
    <div v-if="step === 'form'">
      <form novalidate autocomplete="off">
        <fieldset class="uk-fieldset uk-width-1-1">
          <!-- <label class="uk-form-label uk-flex uk-width-expand" for="email">
            email
          </label> -->

          <input
            id="email"
            class="uk-input"
            placeholder="trooper@handle.fi"
            v-model="email"
            autocomplete="off"
            type="email"
          />
          <button
            class="uk-button uk-button-primary uk-width-expand hfi-button uk-margin-top"
            @click.prevent="onLogin"
            :disabled="!validEmail"
          >
            send login link
          </button>
        </fieldset>
      </form>
    </div>
    <div v-if="step === 'awaiting-login'" class="">
      <p>
        an email has been sent to {{ email }} with login instructions. please
        keep this window open.
      </p>
      <button
        class="uk-button uk-button-primary uk-width-expand hfi-button"
        @click.prevent="backToForm"
      >
        wrong email?
      </button>
    </div>
    <div v-if="step === 'success'">
      <p>login successful</p>
    </div>
  </div>
</template>
<script>
import { loginToMagicWithEmail } from "@/utils/wallet.js";

const StepType = {
  Form: "form",
  AwaitingLogin: "awaiting-login",
  Success: "success",
};

export default {
  name: "EmailLogin",
  data() {
    return {
      step: StepType.Form,
      email: "",
    };
  },
  computed: {
    validEmail: function () {
      return /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
        this.email
      );
    },
  },
  methods: {
    backToForm: function () {
      this.step = StepType.Form;
    },
    onLogin: async function () {
      this.step = StepType.AwaitingLogin;
      await loginToMagicWithEmail(this.email);
      this.step = StepType.Success;
    },
  },
};
</script>
