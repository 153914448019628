import RevertError from "@/types/RevertError";
import Web3Error from "@/types/Web3Error";

const errorFormatters = [RevertError, Web3Error];

/**
 * Formats an error message using the appropriate error formatting module.
 * @param {String} error
 */
const formatError = async (error) => {
  if (typeof error !== "string") error = error.toString();
  for (let errorFormatter of errorFormatters) {
    if (!errorFormatter.Filter(error)) continue;
    return await errorFormatter.Format(error);
  }
  return error;
};

export default formatError;
