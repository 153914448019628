var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"uk-container uk-container-xlarge hfi-dashboard-container"},[_c('vue-headful',{attrs:{"description":"handle.fi dashboard.","image":"src/assets/logo.png","title":((_vm.isLite ? 'handleLite' : 'handle.fi') + " | dashboard")}}),_c('div',[_c('h2',{staticClass:"uk-margin-small-bottom uk-flex-0"},[_vm._v(" "+_vm._s(this.$options.name.toLowerCase())+" ")]),_c('div',{staticClass:"dashboard"},[_c('ul',{staticClass:"uk-tab-bottom",attrs:{"uk-tab":""}},[_c('li',{class:_vm.dashFn === 'wallet' ? 'uk-active' : ''},[_c('a',{attrs:{"href":""},on:{"click":function($event){$event.preventDefault();return _vm.setDashFn('wallet')}}},[_vm._v("wallet")])]),(_vm.network === _vm.Network.arbitrum)?_c('li',{class:((_vm.account && _vm.vaultsStatus
              ? _vm.vaultsStatus === 'warning'
                ? 'hfi-warning'
                : 'hfi-danger'
              : '') + " " + (_vm.dashFn === 'vaults' ? 'uk-active' : '') + "\n          ")},[_c('a',{attrs:{"href":"","uk-tooltip":("title: " + (_vm.vaultsStatus === 'warning'
                ? 'a vault is below its min. CR'
                : _vm.vaultsStatus === 'danger'
                ? 'a vault is at risk of liquidation'
                : 'all vaults are healthy') + "; pos: bottom;")},on:{"click":function($event){$event.preventDefault();return _vm.setDashFn('vaults')}}},[(_vm.vaultsLoading)?_c('i',{staticClass:"fal fa-sync fa-spin"}):(_vm.account && _vm.vaultsStatus === 'danger')?_c('i',{staticClass:"fal fa-ban"}):(_vm.account && _vm.vaultsStatus === 'warning')?_c('i',{staticClass:"fal fa-exclamation-triangle"}):(_vm.account)?_c('i',{staticClass:"fal fa-check-circle"}):_vm._e(),_vm._v(" vaults ")])]):_vm._e(),_c('li',{class:_vm.dashFn === 'transactions' ? 'uk-active' : ''},[_c('a',{attrs:{"href":""},on:{"click":function($event){$event.preventDefault();return _vm.setDashFn('transactions')}}},[_vm._v("transactions")])])]),_c('ul',{staticClass:"uk-switcher"},[_c('li',{class:_vm.dashFn === 'wallet' ? 'uk-active' : ''},[_c('Wallet',{on:{"loaded":_vm.walletLoaded}})],1),(_vm.network === _vm.Network.arbitrum)?_c('li',{class:_vm.dashFn === 'vaults' ? 'uk-active' : ''},[_c('Vaults',{on:{"loaded":_vm.vaultsLoaded}})],1):_vm._e(),_c('li',{class:_vm.dashFn === 'transactions' ? 'uk-active' : ''},[_c('Transactions',{on:{"loaded":_vm.transactionsLoaded}})],1)])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }