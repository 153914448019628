<template>
  <div :id="id + '-wrapper'" :class="wrapperClasses || ''">
    <global-events
      v-if="open"
      @keydown.prevent.38="prevToken"
      @keydown.prevent.40="nextToken"
      @keydown.prevent.enter="setToken"
    ></global-events>

    <div :class="'hfi-select uk-inline ' + selectClasses">
      <span v-if="!disabled" class="uk-form-icon uk-form-icon-flip">
        <i
          v-if="open"
          class="fal fa-chevron-up fa-icon"
          style="font-size: 1.2rem"
        />
        <i
          v-else
          class="fal fa-chevron-down fa-icon"
          style="font-size: 1.2rem"
        />
      </span>

      <span v-if="tokens.length > 0" class="uk-form-icon">
        <img
          width="24"
          :src="
            tokens.find((token) => token.symbol === shownValue)
              ? tokens.find((token) => token.symbol === shownValue).icon
              : 'handle.fiTokenPlaceholder.png'
          "
          :alt="shownValue"
          @error="iconError"
        />
      </span>

      <input
        :disabled="disabled"
        :class="'uk-input ' + inputClasses"
        type="button"
        :value="
          shownValue +
          ' ' +
          (tokens.length > 0 &&
          tokens.find((token) => token.symbol === shownValue) &&
          tokens
            .find((token) => token.symbol === shownValue)
            .name.replace(' Token', '') !== shownValue
            ? tokens
                .find((token) => token.symbol === shownValue)
                .name.replace(' Token', '')
            : '')
        "
      />
    </div>

    <div
      :id="dropdownId"
      class="uk-dropdown uk-width-expand uk-padding-remove"
      :style="
        id === 'selectCurrency'
          ? 'width: 108px;'
          : dropdownWidth
          ? 'width: ' + dropdownWidth + 'px;'
          : ''
      "
      :uk-dropdown="
        'mode: click; delay-hide: 0;' +
        ' pos: ' +
        position +
        ';' +
        (boundaryClass
          ? ` boundary: .${boundaryClass}; boundary-align: true;`
          : '') +
        ` offset: ${offset};`
      "
    >
      <div v-if="search">
        <form class="uk-search uk-flex">
          <span class="uk-search-icon-flip">
            <i class="fal fa-search uk-margin-small-left" />
          </span>
          <input
            ref="search"
            class="uk-input uk-search-input"
            type="search"
            placeholder="search by symbol or address"
            v-model="searchValue"
            @input="changeSearch"
          />
          <a
            v-if="searchValue"
            class="uk-form-icon uk-form-icon-flip"
            @click.prevent="clearSearch"
            uk-tooltip="title: clear search; position: top"
          >
            <i
              class="fal fa-times fa-icon"
              style="margin-right: -4px; font-size: 1.2rem"
            />
          </a>
        </form>
      </div>

      <vue-custom-scrollbar
        :id="id + '-scroll-area'"
        :settings="vueCustomScrollbarSettings"
        class="scroll-area uk-overflow-auto"
        :style="{ maxHeight: maxHeight === '' ? 'unset' : maxHeight + 'px' }"
      >
        <ul class="uk-nav uk-dropdown-nav handle-select-currency">
          <li
            v-if="locatingNewToken"
            :key="'loader'"
            class="hfi-fixed uk-width-expand uk-flex uk-flex-middle"
          >
            <vue-loaders-ball-pulse color="currentColor" scale=".5" />
          </li>

          <li
            v-else-if="newToken"
            :key="typeof newToken === 'string' ? 'notfound' : newToken.symbol"
            :class="`hfi-fixed uk-width-expand
              ${typeof newToken === 'string' ? '' : ' uk-active'}`"
          >
            <a
              :class="`with-icon ${
                typeof newToken === 'string' ? ' disabled' : ''
              }`"
              @click.prevent="
                typeof newToken === 'string'
                  ? undefined
                  : newTokenValue(newToken)
              "
            >
              <img
                width="24"
                :src="
                  typeof newToken === 'string'
                    ? 'handle.fiTokenPlaceholder.png'
                    : newToken.icon
                "
                :alt="
                  typeof newToken === 'string'
                    ? TOKEN_NOT_FOUND_TEXT
                    : newToken.symbol
                "
                @error="iconError"
              />
              {{ typeof newToken === "string" ? newToken : newToken.symbol }}
            </a>
          </li>

          <li
            v-else-if="
              searchValue &&
              sortedTokens.filter(
                (option) =>
                  option &&
                  (option.symbol
                    .toLowerCase()
                    .includes(searchValue.toLowerCase()) ||
                    option.name
                      .toLowerCase()
                      .includes(searchValue.toLowerCase()))
              ).length === 0 &&
              !newToken &&
              newToken !== TOKEN_NOT_FOUND_TEXT
            "
            class="hfi-fixed uk-width-expand"
          >
            <a class="with-icon disabled" @click.prevent="undefined">
              <img
                width="24"
                src="handle.fiTokenPlaceholder.png"
                alt="notfound"
              />
              no matching tokens
            </a>
          </li>

          <li
            v-for="(token, i) in sortedTokens
              .filter(
                (option) =>
                  (searchValue &&
                    option &&
                    (option.symbol
                      .toLowerCase()
                      .includes(searchValue.toLowerCase()) ||
                      option.name
                        .toLowerCase()
                        .includes(searchValue.toLowerCase()))) ||
                  (!searchValue && option.highlight)
              )
              .sort((a, b) =>
                searchValue === '' && sortOptions
                  ? a.symbol.toLowerCase() > b.symbol.toLowerCase()
                    ? 1
                    : -1
                  : a.symbol
                      .toLowerCase()
                      .indexOf(searchValue.toLowerCase()) === -1 ||
                    a.symbol.toLowerCase().indexOf(searchValue.toLowerCase()) >=
                      b.symbol.toLowerCase().indexOf(searchValue.toLowerCase())
                  ? 1
                  : -1
              )"
            :ref="token.symbol"
            :key="value + '_' + token.symbol + '_' + i"
            :class="
              ((token.symbol === value && !token.unavailable) ||
              token.symbol === shownValue
                ? 'uk-active'
                : '') + ' hfi-fixed uk-width-expand'
            "
          >
            <a
              :class="
                'uk-flex-between uk-padding-small-right with-icon ' +
                (token.unavailable ? 'disabled' : '')
              "
              style="display: flex !important"
              @click.prevent="
                token.unavailable ? undefined : changeValue(token.symbol)
              "
              :uk-tooltip="
                token.unavailable ? 'title: coming soon; pos: left;' : undefined
              "
            >
              <span>
                <img
                  width="24"
                  :src="token.icon"
                  :alt="token.symbol"
                  @error="iconError"
                />
                {{ token.symbol }}
                {{
                  token.name.replace(" Token", "") === token.symbol
                    ? ""
                    : token.name.replace(" Token", "")
                }}
              </span>
              {{
                balances &&
                balances[token.symbol] &&
                balances[token.symbol].gt(0)
                  ? `${displayAmount(token, balances[token.symbol])}${
                      showTokenWithBalance ? " " + token.symbol : ""
                    }`
                  : ""
              }}
            </a>
          </li>
        </ul>
      </vue-custom-scrollbar>
    </div>
  </div>
</template>

<script>
import UIkit from "uikit";
import vueCustomScrollbar from "vue-custom-scrollbar";
import "vue-custom-scrollbar/dist/vueScrollbar.css";
import { getToken } from "../utils/coingecko";
import { store } from "@/store";
import Token from "@/types/Token";
import Network from "@/types/Network";
import { ethers } from "ethers";

const TOKEN_NOT_FOUND_TEXT = "token address not found";

export default {
  name: "TokenSelect",
  props: {
    id: { type: String, default: "" },
    wrapperClasses: { type: String, default: "" },
    boundaryClass: { type: String, default: "" },
    selectClasses: { type: String, default: "" },
    inputClasses: { type: String, default: "" },
    value: { type: String, default: "" },
    tokens: { type: Array, default: () => [] },
    disabled: { type: Boolean, default: true },
    position: { type: String, default: "bottom-justify" },
    search: { type: Boolean, default: false },
    sortOptions: { type: Boolean, default: false },
    maxHeight: { type: String, default: "" },
    offset: { type: String, default: "-40" },
    balances: { type: Object, default: () => {} },
    dropdownWidth: { type: String, default: "" },
    showTokenWithBalance: { type: Boolean, default: true },
  },

  components: {
    vueCustomScrollbar,
  },

  data() {
    return {
      open: false,
      dropdownId: this.id + "-dropdown",
      shownValue: this.value,
      searchValue: "",
      vueCustomScrollbarSettings: {
        suppressScrollY: false,
        suppressScrollX: true,
        wheelPropagation: true,
        minScrollbarLength: 15,
        wheelSpeed: 0.125,
      },
      sortedTokens: this.tokens,
      newToken: "",
      number: 0,
      Network,
      Token,
      locatingNewToken: false,
    };
  },

  beforeMount() {
    this.prepareTokens();
  },

  beforeUpdate() {
    this.prepareTokens();
  },

  mounted() {
    this.shownValue = this.value;
    UIkit.util.on(`#${this.dropdownId}.uk-dropdown`, "show", (event) => {
      this.open = true;
      this.$refs.search.focus();
    });
    UIkit.util.on("#" + this.dropdownId + ".uk-dropdown", "hide", () => {
      this.open = false;
    });
  },

  computed: {
    network() {
      return store.state.network;
    },
  },

  watch: {
    value(newValue) {
      this.changeValue(newValue);
    },
    network() {
      this.clearSearch();
      UIkit.dropdown("#" + this.dropdownId + ".uk-dropdown").hide();
    },
  },
  methods: {
    prepareTokens() {
      const sortedTokens = this.tokens.filter((token) => !token.unavailable);
      if (this.sortOptions)
        sortedTokens.sort((a, b) =>
          a.symbol.toLowerCase() > b.symbol.toLowerCase() ? 1 : -1
        );
      this.sortedTokens = sortedTokens;
    },
    nextToken() {
      const tokenList = this.sortedTokens.filter(
        (token) =>
          (this.searchValue &&
            token.symbol
              .toLowerCase()
              .includes(this.searchValue.toLowerCase())) ||
          (!this.searchValue && token.highlight)
      );
      let newPosition =
        tokenList.findIndex((token) => token.symbol === this.shownValue) + 1;
      if (newPosition === tokenList.length) newPosition = tokenList.length - 1;
      this.shownValue = tokenList[newPosition].symbol;
      this.$refs[this.shownValue][0].scrollIntoView();
    },

    prevToken() {
      const tokenList = this.sortedTokens.filter(
        (token) =>
          (this.searchValue &&
            token.symbol
              .toLowerCase()
              .includes(this.searchValue.toLowerCase())) ||
          (!this.searchValue && token.highlight)
      );
      let newPosition =
        tokenList.findIndex((token) => token.symbol === this.shownValue) - 1;
      if (newPosition < 0) newPosition = 0;
      this.shownValue = tokenList[newPosition].symbol;
      this.$refs[this.shownValue][0].scrollIntoView();
    },
    setToken() {
      this.changeValue(this.shownValue);
    },
    changeValue(option) {
      UIkit.dropdown("#" + this.dropdownId + ".uk-dropdown").hide();
      if (
        !this.sortedTokens.find((token) => token.symbol === option)?.unavailable
      ) {
        this.shownValue = option;
        this.$emit("change", option);
        this.searchValue = "";
      }
    },

    newTokenValue(token) {
      UIkit.dropdown("#" + this.dropdownId + ".uk-dropdown").hide();
      this.shownValue = this.newToken.symbol;
      this.$emit("newToken", token);
      this.searchValue = "";
    },

    async changeSearch(event) {
      const value = event.target.value;
      if (value.slice(0, 2) === "0x" && value.length === 42) {
        this.locatingNewToken = true;
        const customToken = await getToken(value);

        if (customToken) {
          // Edge case fix because CoinGecko API returns $DG as dg
          if (customToken.symbol === "dg") customToken.symbol = "$dg";

          if (
            this.sortedTokens.some(
              (tok) =>
                tok.symbol.toLowerCase() === customToken.symbol.toLowerCase()
            )
          ) {
            this.newToken = "";
            this.searchValue = customToken.symbol;
            this.locatingNewToken = false;
            return;
          }
          this.newToken = {
            symbol: customToken.symbol.toUpperCase(),
            address: value,
            icon: customToken.image.large,
            displayDecimals: 4,
            decimals: 18,
            type: "ERC20",
          };
          this.sortedTokens.unshift(this.newToken);
          this.shownValue = this.newToken.symbol;
          this.locatingNewToken = false;
          return;
        }
        this.newToken = TOKEN_NOT_FOUND_TEXT;
        this.locatingNewToken = false;
        return;
      }
      this.newToken = "";
    },

    clearSearch() {
      this.searchValue = "";
      this.newToken = "";
    },

    iconError(event) {
      if (event.target.src.includes("amazonaws"))
        event.target.src = "handle.fiTokenPlaceholder.png";
      else {
        const token = this.tokens.find(
          (t) =>
            t.symbol ===
            (event.target.alt === Token.ETH ? Token.WETH : event.target.alt)
        );

        if (token) {
          event.target.src =
            "https://token-icons.s3.amazonaws.com/" + token.address + ".png";
        }
      }
    },

    displayAmount: function (token, amount) {
      const correctPrecision = ethers.utils.formatUnits(amount, token.decimals);

      const split = correctPrecision.split(".");

      return `${split[0]}.${split[1].substring(0, token.displayDecimals)}`;
    },
  },
};
</script>

<style lang="scss">
@use "src/assets/styles/handle.fi" as handle;

.uk-input[type="button"] {
  cursor: pointer;
}

.uk-input:disabled {
  cursor: default;
}

.symbol-select-dropdown.uk-dropdown {
  padding: 0;
}

.uk-dropdown-nav > li > a {
  padding-left: 10px;
}

.uk-form-icon {
  color: inherit;
}

.with-icon {
  padding-left: 6px !important;
  padding-right: 6px !important;
}

.currency-flag {
  width: 20px;
  height: 15px;
  margin-top: 2px;
}

.currency-flag-dropdown {
  width: 20px;
  height: 15px;
  margin-top: -3px;
}

.uk-search {
  width: 100%;
  display: flex;
  align-items: center;
  border-bottom: 1px solid handle.$green;
}

.uk-search-input {
  cursor: unset;
  height: 36px !important;
}

.uk-search-icon-flip {
  margin-right: 6px !important;
}

.uk-form-icon:hover,
.hfi-select:hover {
  i {
    color: handle.$green;
  }
}

.crypto-icon {
  margin: -2px 2px 0 0;
  width: 24px;
  height: 24px;
}

.hfi-fixed {
  height: 34px;
}

.with-icon img {
  margin-top: -2px;
}

.hfi-fixed:hover span,
.hfi-fixed.uk-active span {
  color: handle.$background;
}
</style>
