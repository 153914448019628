<template xmlns="http://www.w3.org/1999/html">
  <div id="footer" class="uk-position-fixed uk-position-bottom">
    <div
      uk-grid
      :class="`uk-child-width-1-1 uk-child-width-1-3@s uk-flex ${
        isLite ? 'uk-flex-middle' : 'uk-flex-bottom'
      }`"
    >
      <div>
        <div class="uk-flex uk-flex-left uk-margin-left uk-flex-bottom">
          <LiteSelectNetwork
            v-if="showNetworkSelect"
            :networks="liteNetworkOptions"
          />
          <CurrentNetwork v-else-if="showNetwork" />

          <div
            v-if="!isLite && account && gasPriceInGwei"
            class="uk-margin-left"
            style="margin-bottom: 3px"
          >
            <i class="fas fa-gas-pump uk-margin-xsmall-right"></i>
            {{ gasPriceInGwei }}
          </div>
        </div>
      </div>

      <div class="uk-visible@s">
        <div class="uk-flex uk-flex-column uk-flex-center">
          <div v-if="!isLite">
            <router-link to="/data">data</router-link>
            <a
              class="uk-margin-left"
              href="https://docs.handle.fi/"
              target="_blank"
            >
              docs
            </a>
          </div>

          <div
            class="uk-container uk-flex uk-flex-center uk-flex-middle uk-margin-remove-bottom uk-margin-xsmall-top"
          >
            <a href="https://twitter.com/handle_fi" uk-icon="icon: twitter"></a>
            <a
              class="uk-margin-left"
              href="https://discord.gg/77WDThbZJ4"
              uk-icon="icon: discord"
            >
              <i style="font-size: 18px" class="fab fa-discord"></i>
            </a>
            <a
              v-if="!isLite"
              class="uk-margin-left"
              href="https://github.com/handle-fi"
              uk-icon="icon: github"
            ></a>
          </div>
        </div>
      </div>

      <div class="uk-visible@s">
        <div
          class="uk-flex uk-flex-right uk-flex-middle"
          v-if="isLite && account"
        >
          <div>{{ account.slice(0, 6) }}...{{ account.slice(-4) }}</div>
          <div class="uk-flex uk-margin-small-left uk-margin-right">
            <a
              @click="copyToClipboard('footer-account')"
              uk-tooltip="title: copy address to clipboard; pos: bottom;"
              ><i class="fal fa-copy uk-margin-small-right"></i
            ></a>
            <a
              :href="explorerMeta ? explorerMeta.typeUrl : '#'"
              target="_blank"
              :uk-tooltip="
                explorerMeta
                  ? `title: ${explorerMeta.typeMessage}; pos: bottom;`
                  : undefined
              "
            >
              <i class="fal fa-external-link-square"></i>
            </a>
            <input
              class="off-screen"
              :value="account"
              id="footer-account"
              readonly
            />
          </div>
        </div>

        <div
          class="uk-flex uk-flex-right uk-flex-middle uk-flex-wrap"
          v-if="showMagicLogo"
        >
          <span class="uk-margin-right">secured by</span>
          <a href="https://magic.link" target="_blank" title="Magic">
            <img
              uk-svg
              width="75"
              src="/magicLogoWhite.svg"
              class="magic-logo uk-margin-right"
              style="margin-left: -10px"
            />
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { store } from "@/store";
import CurrentNetwork from "@/components/CurrentNetwork";
import LiteSelectNetwork from "@/components/LiteSelectNetwork";
import NetworkType from "@/types/Network";
import { getExplorerMeta, copyToClipboard } from "@/utils/utils";
import VueScreenSize from "vue-screen-size";
import { ethers } from "ethers";
import { provider } from "@/utils/wallet";

export default {
  name: "AppFooter",
  components: {
    CurrentNetwork,
    LiteSelectNetwork,
  },
  mixins: [VueScreenSize.VueScreenSizeMixin],
  data() {
    return {
      liteNetworkOptions: [
        NetworkType.homestead,
        NetworkType.polygon,
        NetworkType.arbitrum,
      ],
      explorerMeta: null,
      copyToClipboard,
      gasPriceInGwei: 0,
      formatEther: (value, digits = 1) =>
        (value
          ? parseFloat(ethers.utils.formatEther(value))
          : 0
        ).toLocaleString(undefined, this.digits(digits)),
    };
  },
  computed: {
    network() {
      return store.state.network;
    },
    provider() {
      return provider;
    },
    account() {
      return store.state.account;
    },
    showNetworkSelect() {
      return store.state.isLite && store.state.account;
    },
    showNetwork() {
      return !store.state.isLite;
    },
    isLite() {
      return store.state.isLite;
    },
    showMagicLogo() {
      return (
        store.state.isLite &&
        (!store.state.account || store.state.initialisingWallet)
      );
    },
  },
  watch: {
    async network() {
      this.onNetworkChange();
      await this.getGasPrice();
    },
    async provider(val) {
      await this.getGasPrice();
    },
    account() {
      this.onNetworkChange();
    },
  },
  async mounted() {
    setInterval(async () => await this.getGasPrice(), 5000);
  },

  methods: {
    async onNetworkChange() {
      if (this.network)
        this.explorerMeta = await getExplorerMeta(this.account, "address");
    },

    async getGasPrice() {
      if (!provider) return;
      const gasPrice = await provider.getGasPrice();
      this.gasPriceInGwei = Number(
        ethers.utils.formatUnits(gasPrice, "gwei")
      ).toFixed(1);
    },

    digits(minDigits, maxDigits = minDigits) {
      return {
        minimumFractionDigits: minDigits,
        maximumFractionDigits: maxDigits,
      };
    },
  },
};
</script>

<style lang="scss">
@use "src/assets/styles/handle.fi.scss" as handle;

#footer {
  max-width: 100vw;
  text-align: center;
  background-color: handle.$background;
  padding: 10px 0;
  z-index: 1;

  a {
    font-weight: normal;
    color: handle.$green;

    &:hover {
      color: handle.$hover;
    }

    &.router-link-exact-active {
      color: handle.$hover;
    }
  }
}

svg.homestead-color {
  .eth-fill-1 {
    fill: handle.$homestead-color !important;
  }
}

svg.ropsten-color {
  .eth-fill-1 {
    fill: handle.$ropsten-color !important;
  }
}

svg.kovan-color {
  .eth-fill-1 {
    fill: handle.$kovan-color !important;
  }
}

svg.rinkeby-color {
  .eth-fill-1 {
    fill: handle.$rinkeby-color !important;
  }
}

svg.goerli-color {
  .eth-fill-1 {
    fill: handle.$goerli-color !important;
  }
}

.disconnected-color {
  opacity: 0.5;
}

.disconnected-color {
  opacity: 0.5;
}
</style>
