<template>
  <div class="select-network uk-inline">
    <button
      class="select-network-button uk-button uk-button-small uk-flex uk-flex-between uk-flex-middle hfi-button"
      type="button"
      :disabled="isConnecting"
      @click="toggleOpen"
    >
      <CurrentNetwork />
      <span v-if="!isConnecting" style="margin-bottom: -8px">
        <i
          v-if="!open"
          class="fal fa-chevron-up fa-icon"
          style="font-size: 1.2rem"
        />
        <i
          v-else
          class="fal fa-chevron-down fa-icon"
          style="font-size: 1.2rem"
        />
      </span>
    </button>
    <div
      class="uk-padding-remove"
      id="select-network-dropdown"
      uk-dropdown="mode: click; delay-hide: 0; pos: top-justify; boundary-align: true; boundary: .select-network; offset: -2;"
    >
      <ul class="uk-nav handle-select">
        <li v-for="nw in networks" :key="nw">
          <a @click.prevent="onSelectNetwork(nw)"> <Network :network="nw" /></a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { store } from "@/store";
import CurrentNetwork from "@/components/CurrentNetwork";
import vueCustomScrollbar from "vue-custom-scrollbar";
import "vue-custom-scrollbar/dist/vueScrollbar.css";
import Network from "@/components/Network";
import { changeMagicNetwork } from "@/utils/wallet";
import UIkit from "uikit";
import VueScreenSize from "vue-screen-size";

export default {
  name: "LiteSelectNetwork",
  components: {
    CurrentNetwork,
    vueCustomScrollbar,
    Network,
  },
  mixins: [VueScreenSize.VueScreenSizeMixin],
  props: {
    networks: { type: Array, default: () => [] },
  },
  data() {
    return {
      open: false,
    };
  },
  computed: {
    network() {
      return store.state.network;
    },
    isConnecting() {
      return store.state.initialisingWallet;
    },
    isLite() {
      return store.state.isLite;
    },
  },

  mounted() {
    UIkit.util.on("#select-network-dropdown", "show", () => {
      this.open = true;
    });
    UIkit.util.on("#select-network-dropdown", "hide", () => {
      this.open = false;
    });
  },
  methods: {
    toggleOpen() {
      if (this.open) UIkit.dropdown("#select-network-dropdown").show();
      else UIkit.dropdown("#select-network-dropdown").hide();
    },
    async onSelectNetwork(network) {
      UIkit.dropdown("#select-network-dropdown").hide();
      changeMagicNetwork(network);
    },
  },
};
</script>
<style lang="scss">
@use "src/assets/styles/handle.fi.scss" as handle;

.handle-select > li.uk-active > a,
.handle-select > li > a:not(.disabled):hover span {
  background-color: #a9e2b0;
  color: #2a313e !important;
}

#select-network-dropdown > ul > li > a {
  padding: 0;
}

.network {
  line-height: 34px;
}
</style>
