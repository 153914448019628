import { store } from "@/store";
import Network from "@/types/Network";
import Token from "@/types/Token";
import contracts from "@/contracts.config.json";

const FOREX_NETWORKS = [Network.arbitrum, Network.homestead];

export const fetchForexBalance = () => {
  if (FOREX_NETWORKS.includes(store.state.network)) {
    store.dispatch("updateBalances", [
      {
        symbol: Token.FOREX,
        address: contracts.homestead.forex
      }
    ]);
  }
};
