export const NETWORK_NAMES = Object.freeze({
  homestead: "ethereum mainnet",
  polygon: "polygon mainnet",
  morden: "deprecated morden testnet",
  ropsten: "ropsten testnet",
  rinkeby: "rinkeby testnet",
  kovan: "kovan testnet",
  goerli: "goerli testnet",
  truffle: "truffle develop",
  ganache: "ganache blockchain",
  rinkebyarbitrum: "rinkeby arbitrum",
  hardhat: "hardhat local",
  arbitrum: "arbitrum one"
});
