import axios from "axios";
import { format, addDays } from "date-fns";

const COVALENT_API_URL = "https://api.covalenthq.com/";
const TWELVEDATA_API_URL = "https://api.twelvedata.com/";

export async function getUSDPrice(token) {
  const axiosInstance = axios.create({
    baseURL: COVALENT_API_URL
  });

  try {
    const result = await axiosInstance.get(
      "v1/pricing/tickers/?tickers=" +
        token +
        "&" +
        "key=" +
        process.env.VUE_APP_COVALENT_API_KEY
    );
    return result.data.data.items[0].quote_rate;
  } catch (error) {
    if (error.response) {
      // Request made and server responded
      console.info("getPrice Error:", error.response);
      throw new Error(error.response.data.message);
    } else if (error.request) {
      // The request was made but no response was received
      console.info("getPrice No Response:", error.request);
      throw new Error(error);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.info("getPrice Other Error:", error.message);
      throw new Error(error);
    }
  }
}

export async function getPrice(from, to) {
  const axiosInstance = axios.create({
    baseURL: TWELVEDATA_API_URL
  });

  try {
    const result = await axiosInstance.get(
      "price" +
        "?symbol=" +
        from +
        "/" +
        to +
        "&" +
        "apikey=" +
        process.env.VUE_APP_12DATA_API_KEY
    );
    return result.data.price;
  } catch (error) {
    if (error.response) {
      // Request made and server responded
      console.info("getPrice Error:", error.response);
      throw new Error(error.response.data.message);
    } else if (error.request) {
      // The request was made but no response was received
      console.info("getPrice No Response:", error.request);
      throw new Error(error);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.info("getPrice Other Error:", error.message);
      throw new Error(error);
    }
  }
}

export async function getPriceData(from, to, days = 1) {
  const endDate = new Date();
  const startDate = addDays(endDate, -days);
  const axiosInstance = axios.create({
    baseURL: TWELVEDATA_API_URL
  });

  try {
    const result = await axiosInstance.get(
      "time_series" +
        "?apikey=" +
        process.env.VUE_APP_12DATA_API_KEY +
        "&symbol=" +
        from +
        "/" +
        to +
        "&timezone=" +
        Intl.DateTimeFormat().resolvedOptions().timeZone +
        "&interval=1min" +
        "&start_date=" +
        format(startDate, "yyyy-MM-dd HH:mm:ss") +
        "&end_date=" +
        format(endDate, "yyyy-MM-dd HH:mm:ss")
    );
    return result.data;
  } catch (error) {
    if (error.response) {
      // Request made and server responded
      console.info("getPrices Error:", error.response);
      throw new Error(error.response.data.message);
    } else if (error.request) {
      // The request was made but no response was received
      console.info("getPrices No Response:", error.request);
      throw new Error(error);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.info("getPrices Other Error:", error.message);
      throw new Error(error);
    }
  }
}
