export default {
  fxJPY: "fxJPY",
  fxAUD: "fxAUD",
  fxCNY: "fxCNY",
  fxKRW: "fxKRW",
  fxEUR: "fxEUR",
  fxSGD: "fxSGD",
  fxPHP: "fxPHP",
  fxGBP: "fxGBP",
  fxUSD: "fxUSD",
  fxCHF: "fxCHF",
  ETH: "ETH",
  WETH: "WETH",
  DAI: "DAI",
  WBTC: "WBTC",
  USDC: "USDC",
  USDT: "USDT",
  LUSD: "LUSD",
  sUSD: "sUSD",
  MATIC: "MATIC",
  EURT: "EURT",
  sEUR: "sEUR",
  FOREX: "FOREX",
  FEI: "FEI"
};
