import Vue from "vue";
import VueRouter from "vue-router";
import Dashboard from "@/views/Dashboard.vue";
import Convert from "@/views/Convert.vue";
import Network from "@/types/Network";
import { store } from "../store";

Vue.use(VueRouter);

const proRoutes = [
  {
    path: "/",
    name: "Dashboard",
    component: Dashboard,
    meta: {
      networks: [
        Network.hardhat,
        Network.homestead,
        Network.arbitrum,
        Network.polygon,
        Network.rinkebyarbitrum
      ]
    }
  },
  {
    path: "/borrow",
    name: "Borrow",
    component: () => import("../views/Borrow.vue"),
    meta: {
      networks: [Network.hardhat, Network.arbitrum, Network.rinkebyarbitrum]
    }
  },
  {
    path: "/convert",
    name: "Convert",
    component: () => import("../views/Convert.vue"),
    meta: {
      networks: [
        Network.hardhat,
        Network.homestead,
        Network.polygon,
        Network.arbitrum
      ]
    }
  },
  {
    path: "/earn",
    name: "Earn",
    component: () => import("../views/Earn.vue"),
    meta: {
      networks: [
        Network.hardhat,
        Network.homestead,
        Network.polygon,
        Network.arbitrum
      ]
    }
  },
  {
    path: "/repay",
    name: "Repay",
    component: () => import("../views/Repay.vue"),
    meta: {
      networks: [Network.hardhat, Network.arbitrum, Network.rinkebyarbitrum]
    }
  },
  /* {
    path: "/redeem",
    name: "Redeem",
    component: () => import("../views/Redeem.vue"),
    meta: {
      networks: [
        Network.hardhat,
        Network.arbitrum,
        Network.hardhat,
        Network.rinkebyarbitrum
      ]
    }
  }, */
  {
    path: "/bridge",
    name: "Bridge",
    component: () => import("../views/Bridge.vue"),
    meta: {
      networks: [Network.arbitrum, Network.homestead, Network.polygon]
    }
  },
  {
    path: "/claim",
    name: "Claim",
    component: () => import("../views/Claim.vue"),
    meta: {
      networks: [
        Network.hardhat,
        Network.homestead,
        Network.kovan,
        Network.rinkebyarbitrum
      ]
    }
  },
  {
    path: "/data",
    name: "Data",
    component: () => import("../views/Data.vue")
  },
  {
    path: "/shop",
    name: "Shop",
    component: () => import("../views/Shop.vue"),
    meta: {
      networks: [Network.hardhat, Network.homestead]
    }
  },
  {
    path: "/tge",
    name: "tge",
    component: () => import("../views/Tge.vue"),
    meta: {
      networks: [
        Network.hardhat,
        Network.kovan,
        Network.rinkebyarbitrum,
        Network.homestead
      ]
    }
  },
  {
    path: "/trade",
    name: "Trade",
    component: () => import("../views/Trade.vue"),
    meta: {
      networks: [Network.homestead, Network.arbitrum, Network.polygon]
    }
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/"
  }
];

const liteRoutes = [
  {
    path: "/",
    name: "Convert",
    component: Convert,
    meta: {
      networks: [
        Network.hardhat,
        Network.homestead,
        Network.polygon,
        Network.arbitrum
      ]
    }
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: () => import("../views/Dashboard.vue"),
    meta: {
      networks: [Network.arbitrum, Network.hardhat, Network.rinkebyarbitrum]
    }
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/"
  }
];

const routes = store.state.isLite ? liteRoutes : proRoutes;

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

const hasQueryParams = (route) => {
  return Object.keys(route.query).length > 0;
};

// Keep query params when changing route.
router.beforeEach((to, from, next) => {
  store.commit("setRoute", to);

  if (!hasQueryParams(to) && hasQueryParams(from)) {
    next({ name: to.name, query: from.query });
  } else {
    next();
  }
});

export default router;
