const mouseDown = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

document.body.onmousedown = function (evt) {
  mouseDown[evt.button] = 1;
};
document.body.onmouseup = function (evt) {
  mouseDown[evt.button] = 0;
};

export default (buttonIndex) => {
  return mouseDown[buttonIndex] !== 0;
};
