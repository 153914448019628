import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import { store } from "./store";
import UIkit from "uikit";
import Icons from "uikit/dist/js/uikit-icons";
import vueHeadful from "vue-headful";
import "@/assets/styles/styles.scss";
import { firestorePlugin } from "vuefire";
import Select from "@/components/Select";
import "vue-loaders/dist/vue-loaders.css";
import VueLoaders from "vue-loaders";
import VueGtag from "vue-gtag";
import VueApollo from "vue-apollo";
import { ApolloClient } from "apollo-client";
import { createHttpLink } from "apollo-link-http";
import { InMemoryCache } from "apollo-cache-inmemory";
import GlobalEvents from "vue-global-events";
import { init as initWallet } from "./utils/wallet";

// HTTP connection to the API
const httpLink = createHttpLink({
  uri: process.env.VUE_APP_HANDLE_API
});

// Cache implementation
const cache = new InMemoryCache();

// Create the apollo client
const apolloClient = new ApolloClient({
  link: httpLink,
  cache
});

import IconCrypto from "vue-cryptocurrency-icons";

Vue.component("vue-headful", vueHeadful);
Vue.component("Select", Select);

UIkit.use(Icons);

Vue.config.productionTip = false;

if (window.ethereum) {
  window.ethereum.autoRefreshOnNetworkChange = false;
}

Vue.use(firestorePlugin);
Vue.use(VueLoaders);

Vue.use(
  VueGtag,
  {
    config: {
      id: "G-10X1PQF3TP",
      params: {
        send_page_view: false
      }
    },
    appName: "handle-app",
    pageTrackerScreenviewEnabled: true
  },
  router
);

Vue.use(VueApollo);

const apolloProvider = new VueApollo({
  defaultClient: apolloClient
});

Vue.use(IconCrypto);

Vue.component("GlobalEvents", GlobalEvents);

new Vue({
  router,
  store,
  apolloProvider,
  GlobalEvents: window.VueGlobalEvents,
  render: (h) => h(App)
}).$mount("#app");

initWallet();
