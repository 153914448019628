import Vue from "vue";
import Vuex from "vuex";
import { HandleSDK, Convert } from "handle-sdk";
import { provider, signer, arbitrumProvider } from "@/utils/wallet";
import { isSupportedNetwork, showNotification } from "@/utils/utils";
import getContractAddresses from "@/contracts/utils/getContractAddresses";
import { NETWORK_NAMES } from "@/utils/constants/networks";
import { getBalances } from "@/utils/utils";
import { networkChoiceLocalStorage } from "@/utils/localStorage";
import Network from "@/types/Network";
import config from "../contracts.config.json";
import { ethers } from "ethers";
import Token from "@/types/Token";
import { fetchForexBalance } from "@/utils/forex";

Vue.use(Vuex);

let handleSDK = null;
let convertSDK = null;
// Store a reference to the SDK object so that Vuex doesn't get annoyed.
let sdkReference = { get: () => handleSDK, counter: 0 };
let convertSDKReference = { get: () => convertSDK, counter: 0 };

export const store = new Vuex.Store({
  strict: true,
  state: {
    refHandleSDK: sdkReference,
    refConvertSDK: convertSDKReference,
    vaultsStatus: null,
    account: null,
    network: null,
    route: null,
    balances: {},
    initialisingWallet: true,
    isLite: process.env.VUE_APP_IS_HANDLE_LITE === "true",
    fromToken: null,
    toToken: null,
    token: null,
    globalSlippage: localStorage.getItem("globalSlippage") || 0.5,
    globalGasPriceOption:
      localStorage.getItem("globalGasPriceOption") || "fast",
    globalGasPriceInGwei: localStorage.getItem("globalGasPriceInGwei"),
    tokenList: [],
    dashFn: "wallet"
  },
  getters: {
    networkDisplayName: (state) => {
      return NETWORK_NAMES[state.network];
    },
    forexBalance: (state) => {
      return state.balances[Token.FOREX];
    }
  },
  mutations: {
    setInitialisingWallet(state, value) {
      state.initialisingWallet = value;
    },
    setHandleSDK(state, value) {
      console.log("setting handle SDK");
      handleSDK = value;
      state.refHandleSDK = {
        get: () => handleSDK,
        counter: state.refHandleSDK.counter + 1
      };
    },
    setConvertSDK(state, value) {
      console.log("setting convert SDK");
      convertSDK = value;
      state.refConvertSDK = {
        get: () => convertSDK,
        counter: state.refConvertSDK.counter + 1
      };
    },
    setVaultsStatus(state, value) {
      state.vaultsStatus = value;
    },
    setAccount(state, account) {
      console.log(`setting account: ${account}`);
      state.account = account;
    },
    setNetwork(state, network) {
      console.log(`setting network: ${network}`);
      networkChoiceLocalStorage.set(network);
      state.network = network;
    },
    setRoute(state, route) {
      state.route = route;
    },
    setBalance(state, balance) {
      state.balances = {
        ...state.balances,
        ...balance
      };
    },
    resetBalances(state) {
      state.balances = {};
    },
    setToken(state, { token, type }) {
      state[`${type || ""}${type ? "Token" : "token"}`] = token;
    },
    setSlippage(state, globalSlippage) {
      localStorage.setItem("globalSlippage", globalSlippage);
      state.globalSlippage = globalSlippage;
    },
    setGasPriceOption(state, globalGasPriceOption) {
      localStorage.setItem("globalGasPriceOption", globalGasPriceOption);
      state.globalGasPriceOption = globalGasPriceOption;
    },
    setGasPriceInGwei(state, globalGasPriceInGwei) {
      localStorage.setItem("globalGasPriceInGwei", globalGasPriceInGwei);
      state.globalGasPriceInGwei = globalGasPriceInGwei;
    },
    setTokenList(state, value) {
      state.tokenList = value;
    },
    setDashFn(state, value) {
      localStorage.setItem("dashFn", value);
      state.dashFn = value;
    }
  },
  actions: {
    async updateBalances({ commit, state }, tokens) {
      if (!signer || !state.account) {
        console.log("No signer or account when updating token balances");
        return;
      }

      const balances = await getBalances(
        await signer.getAddress(),
        signer.provider,
        tokens
      );

      commit("setBalance", balances);
    },
    async updateNetworkAndAccount({ commit, state }, { network, account }) {
      commit("resetBalances");

      if (account !== this.account) {
        commit("setAccount", account);
      }

      if (network !== this.network) {
        if (account === this.account)
          showNotification(
            "success",
            `switching to ${
              NETWORK_NAMES[
                network.toLowerCase() === Network.homestead
                  ? "mainnet"
                  : network
              ]
            }`
          );
        commit("setNetwork", account ? network : null);
      }

      if (!isSupportedNetwork(network)) {
        showNotification(
          "error",
          `You are using the ${network} network, which is not supported.`,
          null,
          0
        );
      }

      if (account) {
        fetchForexBalance();
      }

      if (state.route.name === "tge") return;

      // this is a short term fix.
      // We should show the user if they visit a page that isnt supported by their network
      const supported = [
        Network.arbitrum,
        Network.rinkebyarbitrum,
        Network.hardhat
      ];

      try {
        const convertSDK = new Convert(network);
        commit("setConvertSDK", convertSDK);
      } catch (e) {
        console.error("Convert SDK error:", e);
      }

      if (!supported.includes(network)) {
        console.log(
          "SDK not supported on this network. Using default provider"
        );

        handleSDK = await HandleSDK.from(
          arbitrumProvider,
          config.arbitrum.handle
        );

        commit("setHandleSDK", handleSDK);

        return;
      }

      const signerOrProvider = account ? signer : provider;

      handleSDK = await HandleSDK.from(
        signerOrProvider,
        (await getContractAddresses()).handle
      );

      if (account) {
        fetchForexBalance();
        await handleSDK.loadVaults();
      }

      commit("setHandleSDK", handleSDK);
    },
    async updateTokenList({ commit, state }) {
      if (!state.refConvertSDK.get()) {
        return;
      }

      const tokenList = await state.refConvertSDK.get().getTokens();

      commit("setTokenList", tokenList);
    }
  },
  modules: {}
});
