<template>
  <div id="settings-modal" uk-modal="bgClose: false; container: false;">
    <div
      class="uk-modal-dialog uk-animation-slide-top-medium uk-modal-body uk-margin-auto-vertical uk-padding-small"
      style="max-width: 400px !important"
    >
      <a class="uk-modal-close-default" @click.prevent="closeSettingsModal">
        <i class="fal fa-times"></i>
      </a>

      <ul class="uk-nav uk-dropdown-nav handle-select">
        <li class="uk-margin-small-bottom uk-h4" style="margin-top: -4px">
          <i class="fal fa-sliders-h uk-margin-xsmall-right" />
          settings
        </li>
        <li class="uk-nav-divider"></li>
        <li>
          <div class="uk-margin-small-top">
            <div class="uk-margin">
              <label
                class="uk-form-label uk-flex uk-flex-middle"
                for="slippage"
              >
                max slippage
                <a
                  class="hfi-info-button uk-margin-remove-top"
                  uk-icon="icon: question; ratio: .75"
                  uk-tooltip="title: slippage for the transaction; pos: right"
                />
              </label>

              <div class="uk-flex uk-flex-bottom slippage">
                <SlippageSlider
                  id="slippage"
                  class="uk-flex-1 uk-margin-right"
                  :target-slippage="parseFloat(slippage)"
                  :min-display-slippage="0"
                  :max-display-slippage="20"
                  :min-slider-slippage="0.1"
                  :max-slider-slippage="20"
                  :max-safe-slippage="1"
                  :max-valid-slippage="3"
                  :max-risk-slippage="10"
                  :on-change="onSliderChange"
                />

                <div>
                  <label
                    class="uk-form-label uk-flex uk-flex-middle"
                    for="customSlippage"
                  >
                    custom
                  </label>
                  <input
                    id="customSlippage"
                    v-model="slippage"
                    type="number"
                    min="0.1"
                    max="20"
                    step="0.1"
                    class="uk-input uk-text-right"
                    style="width: 75px"
                  />
                </div>
              </div>
            </div>

            <div class="uk-margin-top uk-flex uk-flex-between uk-flex-middle">
              <div
                class="uk-flex uk-width-expand uk-flex-column uk-margin-right"
              >
                <label class="uk-form-label" for="gasPriceOption">
                  gas price option
                </label>
                <Select
                  id="gasPriceOption"
                  class="uk-width-expand"
                  inputClasses="uk-text-left"
                  selectClasses="gas-price-boundary uk-width-expand"
                  boundaryClass="gas-price-boundary"
                  :options="['fast', 'very fast', 'custom']"
                  :value="gasPriceOption"
                  @change="onSelectGasPriceOption"
                  :disabled="false"
                />
              </div>

              <div class="uk-flex uk-flex-column">
                <label
                  :style="
                    this.gasPriceOption !== 'custom' ? 'opacity: 0.5;' : ''
                  "
                  class="uk-form-label"
                  for="gasPriceInGwei"
                  :uk-tooltip="
                    gasPriceOption === 'custom'
                      ? `title: ${
                          gasPriceOption === 'custom' &&
                          gasPriceInGwei === gasPriceLevelsInGwei.fastest
                            ? 'very fast value shown as a basis'
                            : 'set custom gas price'
                        }; pos: bottom`
                      : undefined
                  "
                >
                  gwei
                  <i
                    v-if="gasPriceOption === 'custom'"
                    class="fal fa-question-circle"
                  />
                </label>
                <input
                  :style="
                    this.gasPriceOption !== 'custom' ? 'opacity: 0.5;' : ''
                  "
                  id="gasPriceInGwei"
                  ref="gasPriceInGwei"
                  v-model="gasPriceInGwei"
                  type="number"
                  min="0"
                  step="0.1"
                  style="width: 75px"
                  class="uk-input uk-text-right"
                  :readonly="this.gasPriceOption !== 'custom'"
                />
              </div>
            </div>

            <div class="uk-margin-top">
              <button
                id="button"
                class="uk-button uk-width-expand hfi-button"
                type="button"
                :disabled="!canSave"
                @click="onSaveSettings"
              >
                save settings
              </button>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { store } from "@/store";
import { showNotification } from "@/utils/utils";
import { provider } from "@/utils/wallet";
import Select from "@/components/Select";
import SlippageSlider from "@/components/SlippageSlider";
import { ethers } from "ethers";
import UIkit from "uikit";

export default {
  name: "SettingsModal",
  components: {
    Select,
    SlippageSlider,
  },
  data() {
    return {
      slippage: store.state.globalSlippage || "0.5",
      gasPriceLevelsInGwei: { fast: "0", fastest: "0" },
      gasPriceOption: store.state.globalGasPriceOption || "fast",
      gasPriceInGwei: store.state.globalGasPriceInGwei || "",
    };
  },
  async mounted() {
    await this.onNetworkChange();
  },
  computed: {
    isLite() {
      return store.state.isLite;
    },
    network() {
      return store.state.network;
    },
    account() {
      return store.state.account;
    },
    globalSlippage() {
      return store.state.globalSlippage;
    },
    globalGasPriceOption() {
      return store.state.globalGasPriceOption;
    },
    globalGasPriceInGwei() {
      return store.state.globalGasPriceInGwei;
    },
    canSave() {
      return (
        this.slippage !== this.globalSlippage ||
        this.gasPriceOption !== this.globalGasPriceOption ||
        (this.gasPriceOption === "custom" &&
          this.gasPriceInGwei !== this.globalGasPriceInGwei)
      );
    },
  },
  watch: {
    async network(value) {
      await this.onNetworkChange();
    },
    async account(value) {
      await this.onNetworkChange();
    },
  },
  methods: {
    async onNetworkChange() {
      if (this.network) {
        await this.getGasPrice();
      }
    },

    async closeSettingsModal() {
      UIkit.modal("#settings-modal").hide();
      await this.onNetworkChange();
    },

    async getGasPrice() {
      if (!provider) {
        this.gasPriceInGwei = "0";
        return {
          fast: "0",
          fastest: "0",
        };
      }

      const gasPrice = await provider.getGasPrice();
      const gasPriceInGwei = Number(ethers.utils.formatUnits(gasPrice, "gwei"));

      this.gasPriceLevelsInGwei = {
        fast: (gasPriceInGwei * 1.2).toFixed(1),
        fastest: (gasPriceInGwei * 1.3).toFixed(1),
      };

      if (this.gasPriceOption === "fast") {
        this.gasPriceInGwei = this.gasPriceLevelsInGwei.fast;
      } else if (this.gasPriceOption === "very fast") {
        this.gasPriceInGwei = this.gasPriceLevelsInGwei.fastest;
      } else {
        this.gasPriceInGwei =
          this.globalGasPriceInGwei || this.gasPriceLevelsInGwei.fastest;

        this.$refs.gasPriceInGwei.focus();
        return;
      }
    },

    onSliderChange(value) {
      this.slippage = value;
    },

    onSelectGasPriceOption(option) {
      this.gasPriceOption = option;

      if (option === "fast") {
        this.gasPriceInGwei = this.gasPriceLevelsInGwei.fast;
      } else if (option === "very fast") {
        this.gasPriceInGwei = this.gasPriceLevelsInGwei.fastest;
      } else {
        this.gasPriceInGwei =
          this.globalGasPriceInGwei || this.gasPriceLevelsInGwei.fastest;

        this.$refs.gasPriceInGwei.focus();
        return;
      }
    },

    onSaveSettings() {
      store.commit("setSlippage", this.slippage);
      store.commit("setGasPriceOption", this.gasPriceOption);
      store.commit(
        "setGasPriceInGwei",
        this.gasPriceOption === "custom" ? this.gasPriceInGwei : undefined
      );
      showNotification("success", "settings saved successfully");
    },
  },
};
</script>
