var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.wrapperClasses || '',attrs:{"id":_vm.id + '-wrapper'}},[(_vm.open)?_c('global-events',{on:{"keydown":[function($event){if(!$event.type.indexOf('key')&&$event.keyCode!==38){ return null; }$event.preventDefault();return _vm.prevToken($event)},function($event){if(!$event.type.indexOf('key')&&$event.keyCode!==40){ return null; }$event.preventDefault();return _vm.nextToken($event)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }$event.preventDefault();return _vm.setToken($event)}]}}):_vm._e(),_c('div',{class:'hfi-select uk-inline ' + _vm.selectClasses},[(!_vm.disabled)?_c('span',{staticClass:"uk-form-icon uk-form-icon-flip"},[(_vm.open)?_c('i',{staticClass:"fal fa-chevron-up fa-icon",staticStyle:{"font-size":"1.2rem"}}):_c('i',{staticClass:"fal fa-chevron-down fa-icon",staticStyle:{"font-size":"1.2rem"}})]):_vm._e(),(_vm.tokens.length > 0)?_c('span',{staticClass:"uk-form-icon"},[_c('img',{attrs:{"width":"24","src":_vm.tokens.find(function (token) { return token.symbol === _vm.shownValue; })
            ? _vm.tokens.find(function (token) { return token.symbol === _vm.shownValue; }).icon
            : 'handle.fiTokenPlaceholder.png',"alt":_vm.shownValue},on:{"error":_vm.iconError}})]):_vm._e(),_c('input',{class:'uk-input ' + _vm.inputClasses,attrs:{"disabled":_vm.disabled,"type":"button","value":_vm.shownValue +
        ' ' +
        (_vm.tokens.length > 0 &&
        _vm.tokens.find(function (token) { return token.symbol === _vm.shownValue; }) &&
        _vm.tokens
          .find(function (token) { return token.symbol === _vm.shownValue; })
          .name.replace(' Token', '') !== _vm.shownValue
          ? _vm.tokens
              .find(function (token) { return token.symbol === _vm.shownValue; })
              .name.replace(' Token', '')
          : '')}})]),_c('div',{staticClass:"uk-dropdown uk-width-expand uk-padding-remove",style:(_vm.id === 'selectCurrency'
        ? 'width: 108px;'
        : _vm.dropdownWidth
        ? 'width: ' + _vm.dropdownWidth + 'px;'
        : ''),attrs:{"id":_vm.dropdownId,"uk-dropdown":'mode: click; delay-hide: 0;' +
      ' pos: ' +
      _vm.position +
      ';' +
      (_vm.boundaryClass
        ? (" boundary: ." + _vm.boundaryClass + "; boundary-align: true;")
        : '') +
      " offset: " + _vm.offset + ";"}},[(_vm.search)?_c('div',[_c('form',{staticClass:"uk-search uk-flex"},[_vm._m(0),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.searchValue),expression:"searchValue"}],ref:"search",staticClass:"uk-input uk-search-input",attrs:{"type":"search","placeholder":"search by symbol or address"},domProps:{"value":(_vm.searchValue)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.searchValue=$event.target.value},_vm.changeSearch]}}),(_vm.searchValue)?_c('a',{staticClass:"uk-form-icon uk-form-icon-flip",attrs:{"uk-tooltip":"title: clear search; position: top"},on:{"click":function($event){$event.preventDefault();return _vm.clearSearch($event)}}},[_c('i',{staticClass:"fal fa-times fa-icon",staticStyle:{"margin-right":"-4px","font-size":"1.2rem"}})]):_vm._e()])]):_vm._e(),_c('vue-custom-scrollbar',{staticClass:"scroll-area uk-overflow-auto",style:({ maxHeight: _vm.maxHeight === '' ? 'unset' : _vm.maxHeight + 'px' }),attrs:{"id":_vm.id + '-scroll-area',"settings":_vm.vueCustomScrollbarSettings}},[_c('ul',{staticClass:"uk-nav uk-dropdown-nav handle-select-currency"},[(_vm.locatingNewToken)?_c('li',{key:'loader',staticClass:"hfi-fixed uk-width-expand uk-flex uk-flex-middle"},[_c('vue-loaders-ball-pulse',{attrs:{"color":"currentColor","scale":".5"}})],1):(_vm.newToken)?_c('li',{key:typeof _vm.newToken === 'string' ? 'notfound' : _vm.newToken.symbol,class:("hfi-fixed uk-width-expand\n            " + (typeof _vm.newToken === 'string' ? '' : ' uk-active'))},[_c('a',{class:("with-icon " + (typeof _vm.newToken === 'string' ? ' disabled' : '')),on:{"click":function($event){$event.preventDefault();typeof _vm.newToken === 'string'
                ? undefined
                : _vm.newTokenValue(_vm.newToken)}}},[_c('img',{attrs:{"width":"24","src":typeof _vm.newToken === 'string'
                  ? 'handle.fiTokenPlaceholder.png'
                  : _vm.newToken.icon,"alt":typeof _vm.newToken === 'string'
                  ? _vm.TOKEN_NOT_FOUND_TEXT
                  : _vm.newToken.symbol},on:{"error":_vm.iconError}}),_vm._v(" "+_vm._s(typeof _vm.newToken === "string" ? _vm.newToken : _vm.newToken.symbol)+" ")])]):(
            _vm.searchValue &&
            _vm.sortedTokens.filter(
              function (option) { return option &&
                (option.symbol
                  .toLowerCase()
                  .includes(_vm.searchValue.toLowerCase()) ||
                  option.name
                    .toLowerCase()
                    .includes(_vm.searchValue.toLowerCase())); }
            ).length === 0 &&
            !_vm.newToken &&
            _vm.newToken !== _vm.TOKEN_NOT_FOUND_TEXT
          )?_c('li',{staticClass:"hfi-fixed uk-width-expand"},[_c('a',{staticClass:"with-icon disabled",on:{"click":function($event){$event.preventDefault();return undefined($event)}}},[_c('img',{attrs:{"width":"24","src":"handle.fiTokenPlaceholder.png","alt":"notfound"}}),_vm._v(" no matching tokens ")])]):_vm._e(),_vm._l((_vm.sortedTokens
            .filter(
              function (option) { return (_vm.searchValue &&
                  option &&
                  (option.symbol
                    .toLowerCase()
                    .includes(_vm.searchValue.toLowerCase()) ||
                    option.name
                      .toLowerCase()
                      .includes(_vm.searchValue.toLowerCase()))) ||
                (!_vm.searchValue && option.highlight); }
            )
            .sort(function (a, b) { return _vm.searchValue === '' && _vm.sortOptions
                ? a.symbol.toLowerCase() > b.symbol.toLowerCase()
                  ? 1
                  : -1
                : a.symbol
                    .toLowerCase()
                    .indexOf(_vm.searchValue.toLowerCase()) === -1 ||
                  a.symbol.toLowerCase().indexOf(_vm.searchValue.toLowerCase()) >=
                    b.symbol.toLowerCase().indexOf(_vm.searchValue.toLowerCase())
                ? 1
                : -1; }
            )),function(token,i){return _c('li',{key:_vm.value + '_' + token.symbol + '_' + i,ref:token.symbol,refInFor:true,class:((token.symbol === _vm.value && !token.unavailable) ||
            token.symbol === _vm.shownValue
              ? 'uk-active'
              : '') + ' hfi-fixed uk-width-expand'},[_c('a',{class:'uk-flex-between uk-padding-small-right with-icon ' +
              (token.unavailable ? 'disabled' : ''),staticStyle:{"display":"flex !important"},attrs:{"uk-tooltip":token.unavailable ? 'title: coming soon; pos: left;' : undefined},on:{"click":function($event){$event.preventDefault();token.unavailable ? undefined : _vm.changeValue(token.symbol)}}},[_c('span',[_c('img',{attrs:{"width":"24","src":token.icon,"alt":token.symbol},on:{"error":_vm.iconError}}),_vm._v(" "+_vm._s(token.symbol)+" "+_vm._s(token.name.replace(" Token", "") === token.symbol ? "" : token.name.replace(" Token", ""))+" ")]),_vm._v(" "+_vm._s(_vm.balances && _vm.balances[token.symbol] && _vm.balances[token.symbol].gt(0) ? ("" + (_vm.displayAmount(token, _vm.balances[token.symbol])) + (_vm.showTokenWithBalance ? " " + token.symbol : "")) : "")+" ")])])})],2)])],1)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"uk-search-icon-flip"},[_c('i',{staticClass:"fal fa-search uk-margin-small-left"})])}]

export { render, staticRenderFns }