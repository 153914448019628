<template>
  <div
    id="network-modal"
    v-if="$route.name"
    :class="'uk-modal' + (open ? ' uk-open uk-flex' : '')"
    :key="(network || 'disconnected') + '_' + (open ? 'open' : 'closed')"
    uk-modal="bgClose: true; escClose: true; container: #app;"
  >
    <div
      :class="
        'uk-modal-dialog uk-animation-slide-top-medium uk-modal-body uk-margin-auto-vertical uk-padding-small uk-text-center uk-text-warning' +
        (open ? ' uk-animation-slide-bottom-medium' : '')
      "
    >
      <div class="hfi-warning uk-position-absolute uk-position-top-left">
        <i class="fal fa-exclamation-triangle"></i>
      </div>
      <a class="uk-modal-close-default">
        <i class="fal fa-times"></i>
      </a>
      <p class="uk-margin-remove-top@s">
        you are connected to {{ networkName }}.
      </p>
      <p>the {{ routeName }} function is not available on this network.</p>
      <p
        v-if="
          networkNames.filter((nwk) => nwk !== 'hardhat local').length === 0
        "
      >
        there are no supported networks for the
        {{ routeName }} function.
      </p>
      <p v-else>
        {{
          networkNames.filter((nwk) => nwk !== "hardhat local").length > 1
            ? ""
            : "the "
        }}supported network{{
          networkNames.filter((nwk) => nwk !== "hardhat local").length > 1
            ? "s"
            : ""
        }}
        for the {{ routeName }} function
        {{
          networkNames.filter((nwk) => nwk !== "hardhat local").length > 1
            ? "are"
            : "is"
        }}
        {{
          (networkNames.filter((nwk) => nwk !== "hardhat local").length > 1
            ? networkNames
                .filter((nwk) => nwk !== "hardhat local")
                .slice(0, -1)
                .join(", ") + " and "
            : "") + networkNames.slice(-1)
        }}.
      </p>
      <p>
        please switch to
        {{
          networkNames.filter((nwk) => nwk !== "hardhat local").length > 1
            ? "one of these networks"
            : "this network"
        }}
        to continue.
      </p>
      <div class="uk-button-group">
        <button
          :key="ix"
          v-for="(network, ix) of networkNames.filter(
            (networkName) => networkName !== 'hardhat local'
          )"
          class="uk-button uk-button-small hfi-yellow-button"
          @click.prevent="switchNetwork(network)"
        >
          {{ network }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { store } from "@/store";
import { closeAllNotifications } from "@/utils/utils";
import { NETWORK_NAMES } from "@/utils/constants/networks";
import { switchNetwork } from "@/utils/wallet";

export default {
  name: "NetworkModal",
  data() {
    return {
      walletUpdateEventId: null,
      open: false,
    };
  },
  watch: {
    network() {
      this.resetState();
    },
    account() {
      this.resetState();
    },
  },
  computed: {
    network() {
      return store.state.network;
    },
    networks() {
      return this.$route.meta.networks;
    },
    account() {
      return store.state.account;
    },
    routeName() {
      return this.$route.name?.toLowerCase() || "";
    },
    networkName() {
      return this.network ? NETWORK_NAMES[this.network] : "undefined network";
    },
    networkNames() {
      if (!this.networks) return [];
      return this.networks.map((network) => NETWORK_NAMES[network]);
    },
  },

  async mounted() {
    this.initialiseState();
  },

  async updated() {
    this.initialiseState();
  },

  beforeDestroy() {
    removeEventListener(Event.WalletUpdate, this.walletUpdateEventId);
    closeAllNotifications();
  },

  methods: {
    initialiseState() {
      removeEventListener(Event.WalletUpdate, this.walletUpdateEventId);
      this.walletUpdateEventId = addEventListener(
        Event.WalletUpdate,
        this.resetState.bind(this)
      );

      this.resetState();
    },

    resetState() {
      if (this.network) this.checkNetwork();
      else this.open = false;
    },

    async checkNetwork() {
      if (this.networks?.length > 0 && this.networks.includes(this.network)) {
        closeAllNotifications();
        this.open = false;
      } else {
        closeAllNotifications();
        this.open = true;
      }
    },
    switchNetwork(network) {
      const newNetwork = this.networks[
        this.networkNames.findIndex((nwk) => nwk === network)
      ];
      switchNetwork(newNetwork);
    },
  },
};
</script>

<style lang="scss" scoped>
@use "src/assets/styles/handle.fi" as handle;
.uk-modal-dialog {
  border-color: handle.$warning-color;
  a {
    i {
      color: handle.$warning-color;
    }
    &:hover {
      i {
        color: #b8b8b8;
      }
    }
  }
  p {
    color: handle.$warning-color !important;
  }
}

.hfi-warning {
  color: handle.$warning-color;
  top: 14px;
  left: 16px;
}
</style>
