import UIkit from "uikit";
import web3 from "./web3";
import config from "../contracts.config";
import { store } from "@/store";
import Token from "../types/Token";
import { batch, contract as batchContract } from "@pooltogether/etherplex";
import erc20ABI from "../abi/erc20";
import NetworkType from "../types/Network";

import { ethers } from "ethers";

export const getTokenAddress = (token) => {
  return config[store.state.network][token];
};

/**
 * Changes a BigNumber value according to interpreted decimals
 * @param {ethers.BigNumber} amount
 * @param {Number} fromDecimals
 * @param {Number} toDecimals
 * @returns {ethers.BigNumber} amount with toDecimals
 */
export const getDecimalsAmount = (amount, fromDecimals, toDecimals) => {
  if (fromDecimals === toDecimals) return amount;
  const ten = ethers.BigNumber.from("10");
  if (fromDecimals < toDecimals) {
    // Multiply to scale up.
    const factor = toDecimals - fromDecimals;
    return amount.mul(ten.pow(factor));
  } else {
    // Divide to scale down.
    const factor = fromDecimals - toDecimals;
    return amount.div(ten.pow(factor));
  }
};

export const getPairRate = async (fromToken, toToken, amount) => {
  fromToken = getTokenAddress(fromToken);
  toToken = getTokenAddress(toToken);

  amount = web3.utils.toBN(
    web3.utils.toWei(String(amount), "ether").toString()
  );

  const Token = new web3.eth.Contract(
    HandleRouter.abi,
    getTokenAddress("router")
  );
  const rate = web3.utils.fromWei(
    await Token.methods.getAmountOut(amount, toToken, fromToken).call(),
    "ether"
  );

  return rate;
};

export const isSupportedNetwork = (network) => {
  return Object.keys(config).includes(network);
};

export const getExplorerMeta = async (hash, type = "address") => {
  const network = await store.state.network;

  const explorerData = (() => {
    switch (network) {
      case NetworkType.polygon:
        return {
          name: "polygonscan",
          domain: "polygonscan.com"
        };
      case NetworkType.arbitrum:
        return {
          name: "arbiscan",
          domain: "arbiscan.io"
        };
      case NetworkType.rinkebyarbitrum:
        return {
          name: "arbiscan",
          domain: "testnet.arbiscan.io"
        };
      default:
        return {
          name: "etherscan",
          domain:
            network === NetworkType.homestead
              ? "etherscan.io"
              : `${network}.etherscan.io`
        };
    }
  })();

  const typeUrl = `https://${explorerData.domain}/${type}/${hash}`;

  const typeMessage =
    type === "tx" ? "transaction" : type === "token" ? "contract" : type;

  const link = `<a href="${typeUrl}" target="_blank">${explorerData.name}</a>`;

  return {
    name: explorerData.name,
    url: explorerData.domain,
    typeUrl,
    typeMessage: `view ${typeMessage} on ${explorerData.name}`,
    typeMessageWithLink: `view ${typeMessage} on ${link}`,
    typeMessageWithLinkShort: `view on ${link}`
  };
};

export const copyToClipboard = (id) => {
  const copyText = document.getElementById(id);
  copyText.select();
  document.execCommand("copy");
  showNotification("success", "address copied to clipboard");
};

/**
 * Shows a new notification.
 * @param {string} status
 * @param {string} message
 * @param {string} [position]
 * @param {number} [timeout]
 * @param {number} [delay]
 * @returns {UIkit.notification} notification
 */
export const showNotification = async (
  status,
  message,
  position,
  timeout = 4000,
  delay = 0
) => {
  if (!["primary", "success", "warning", "danger", "gorilla"].includes(status))
    status = "danger";

  if (position == null) position = "bottom-right";

  const messageIcon = ["primary", "success"].includes(status)
    ? "fa-check-circle"
    : status === "warning"
    ? "fa-exclamation-triangle"
    : status === "danger"
    ? "fa-ban"
    : undefined;

  const messageImage =
    status === "gorilla"
      ? "<img src='handle.fiDancingGorilla.gif' class='position-relative uk-margin-small-right' alt='handle.fi dancing gorilla' width='25' />"
      : undefined;
  const fullMessage =
    (messageIcon
      ? "<i class='fal fa-fw " + messageIcon + " uk-margin-small-right'></i>"
      : "") +
    (messageImage ? messageImage : "") +
    message;

  await new Promise((r) => setTimeout(r, delay));

  return UIkit.notification({
    status: ["primary", "success", "gorilla"].includes(status)
      ? "success"
      : status,
    message: fullMessage,
    pos: position,
    timeout: timeout
  });
};

/**
 * Shows a new notification if none with the same message is currently visible.
 * @param {string} status
 * @param {string} message
 * @param {string} [position]
 * @param {number} [timeout]
 */
export const showUniqueNotification = (status, message, position, timeout) => {
  // Prevent message from being displayed more than once.
  const notifications = window.document.querySelectorAll(
    ".uk-notification div"
  );
  for (let notification of notifications) {
    // Use endsWith to check if current message exists as UIKit prepends the message with a symbol,
    // which adds extra whitespace.
    if (notification?.textContent?.endsWith(message)) return;
  }
  showNotification(status, message, position, timeout);
};

export const closeAllNotifications = () => {
  UIkit.notification.closeAll();
};

/**
 * Formats a number into a price string.
 * @param {number} value
 */
export const formatPrice = (value) =>
  value.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");

export const isNativeToken = (token) => {
  if (store.state.network === NetworkType.polygon && token === Token.MATIC) {
    return true;
  }

  if (token === Token.ETH) {
    return true;
  }

  return false;
};

/**
 * @param {ethers.Provider} provider
 * @param {{ symbol: string, address?: string }[]} tokens
 * @returns {{ [key: string]: ethers.BigNumber }}
 */
export const getBalances = async (address, provider, tokens) => {
  const nativeToken = tokens.find((t) => isNativeToken(t.symbol));

  const nativeBalance = nativeToken
    ? {
        [nativeToken.symbol]: await provider.getBalance(address)
      }
    : {};

  const tokenBalancesResult = await batch(
    provider,
    ...tokens
      .filter((t) => !isNativeToken(t.symbol))
      .map((t) => {
        return batchContract(t.symbol, erc20ABI, t.address).balanceOf(address);
      })
  );

  const tokenBalances = Object.keys(tokenBalancesResult).reduce(
    (progress, current) => {
      return {
        ...progress,
        [current]: tokenBalancesResult[current].balanceOf[0]
      };
    },
    {}
  );

  return {
    ...nativeBalance,
    ...tokenBalances
  };
};
