export default {
  homestead: "homestead",
  arbitrum: "arbitrum",
  polygon: "polygon",
  rinkebyarbitrum: "rinkebyarbitrum",
  morden: "morden",
  ropsten: "ropsten",
  rinkeby: "rinkeby",
  kovan: "kovan",
  goerli: "goerli",
  truffle: "truffle",
  ganache: "ganache",
  hardhat: "hardhat"
};
