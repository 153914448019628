import ethRevertReason from "eth-revert-reason";
import { store } from "@/store";

export const getRevertReasonFromTxHash = async (txHash) => {
  try {
    return await ethRevertReason(txHash, store.state.network);
  } catch (e) {
    console.error("Error retrieving revert reason:", e);
    return null;
  }
};

export const getRevertReasonFromErrorMessage = async (error) => {
  let parsedData;
  try {
    parsedData = JSON.parse(
      error.message.substring(error.message.indexOf("{"))
    );
  } catch (e) {
    console.error("Could not parse error data for fetching revert reason:", e);
  }
  const txHash = parsedData?.transactionHash;
  return await getRevertReasonFromTxHash(txHash);
};
