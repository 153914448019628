export const NETWORK_PARMS = Object.freeze({
  homestead: {
    chainName: "Ethereum Mainnet",
    chainId: "0x1",
    nativeCurrency: {
      name: "Ether",
      symbol: "ETH",
      decimals: 18
    },
    rpcUrls: ["https://mainnet.infura.io/v3/" + process.env.VUE_APP_INFURA_ID],
    blockExplorerUrls: ["https://etherscan.io"]
  },
  polygon: {
    chainName: "Polygon Mainnet",
    chainId: "0x89",
    nativeCurrency: {
      name: "Matic",
      symbol: "MATIC",
      decimals: 18
    },
    rpcUrls: ["https://rpc-mainnet.maticvigil.com/"],
    blockExplorerUrls: ["https://explorer.matic.network/"]
  },
  arbitrum: {
    chainName: "Arbitrum One",
    chainId: "0xA4B1",
    nativeCurrency: {
      name: "Arbitrum Ether",
      symbol: "AETH",
      decimals: 18
    },
    rpcUrls: [
      "https://arb-mainnet.g.alchemy.com/v2/QFmo_IJ-6WUS5-IMbM8HYFkjJW3VW25k"
    ],
    blockExplorerUrls: ["https://arbiscan.io"]
  },
  rinkebyarbitrum: {
    chainName: "Arbitrum Testnet",
    chainId: "0x66EEB",
    nativeCurrency: {
      name: "Arbitrum Ether",
      symbol: "AETH",
      decimals: 18
    },
    rpcUrls: [
      "https://arb-rinkeby.g.alchemy.com/v2/Xp6EK9frm2n1D0Nz6PY-69AjX5uFReKe"
    ],
    blockExplorerUrls: ["https://rinkeby-explorer.arbitrum.io/#/"]
  }
});
