import { ethers } from "ethers";
import NetworkTypes from "@/types/Network";

export const getAlchemyRpc = (network) => {
  const map = {
    [NetworkTypes.homestead]:
      "https://eth-mainnet.alchemyapi.io/v2/G8tTVR1JfR9gwz4nvzMg8CiYqxBSYp_0",
    [NetworkTypes.arbitrum]:
      "https://arb-mainnet.g.alchemy.com/v2/HORad5Nv96-kPzIx9oEPU0tCEiIVp-Oz",
    [NetworkTypes.rinkebyarbitrum]:
      "https://arb-rinkeby.g.alchemy.com/v2/Xp6EK9frm2n1D0Nz6PY-69AjX5uFReKe",
    [NetworkTypes.polygon]:
      "https://polygon-mainnet.g.alchemy.com/v2/Z8v2aDiCHNPzfVbFB8tZj9tp47_za-PU"
  };

  return map[network];
};

export const createRpcProvider = (network) => {
  const alcRpc = getAlchemyRpc(network);

  if (!alcRpc) {
    throw new Error("Unsupported network");
  }

  return new ethers.providers.JsonRpcProvider(alcRpc);
};
