import { ethers, providers } from "ethers";
import { Magic } from "magic-sdk";
import { OAuthExtension } from "@magic-ext/oauth";
import WalletConnectProvider from "@walletconnect/web3-provider";
import Event from "@/types/Event";
import { triggerEvent } from "./event";
import WalletTypes from "@/types/Wallet";
import NetworkTypes from "@/types/Network";
import config from "@/contracts.config";
import { store } from "@/store";
import { queryStringToObject } from "@/utils/url";
import {
  walletChoiceLocalStorage,
  networkChoiceLocalStorage
} from "@/utils/localStorage";
import { NETWORK_PARMS } from "@/utils/constants/networkParms";
import { createRpcProvider, getAlchemyRpc } from "@/utils/alchemy";

let currentWeb3 = null;
let magic = null;
export let disconnectFromWalletConnect = null;
export let provider = null;
export let signer = null;

export const homesteadProvider = createRpcProvider(NetworkTypes.homestead);
export const arbitrumProvider = createRpcProvider(NetworkTypes.arbitrum);
export const polygonProvider = createRpcProvider(NetworkTypes.polygon);

const initMagic = (network) => {
  const getNetworkRpc = () => {
    switch (network) {
      case NetworkTypes.polygon:
        return {
          rpcUrl: getAlchemyRpc(NetworkTypes.polygon),
          chainId: 137
        };
      case NetworkTypes.arbitrum:
        return {
          rpcUrl: getAlchemyRpc(NetworkTypes.arbitrum),
          chainId: 42161
        };
      default:
        return {};
    }
  };

  magic = new Magic(process.env.VUE_APP_MAGIC_PUBLISHABLE_KEY, {
    network: getNetworkRpc(),
    extensions: [new OAuthExtension()]
  });
};

export const changeMagicNetwork = async (network) => {
  store.commit("setInitialisingWallet", true);
  networkChoiceLocalStorage.set(network);
  initMagic(network);
  await connectToMagic();
  store.commit("setInitialisingWallet", false);
};

const getNetworkName = async (provider) => {
  const network = await provider.getNetwork();
  return Object.keys(config).find((x) => config[x].chainId === network.chainId);
};

const setProvider = async (p) => {
  provider = p;
  const network = await getNetworkName(p);
  store.commit("setNetwork", network);
  triggerEvent(Event.WalletUpdate);
};

const setSigner = async (p) => {
  provider = p;
  signer = p.getSigner();
  const network = await getNetworkName(p);
  const account = await signer.getAddress();
  store.dispatch("updateNetworkAndAccount", { network, account });
  triggerEvent(Event.WalletUpdate);
};

const resetSigner = () => {
  signer = null;
  if (store) {
    store.commit("setAccount", null);
  }
  triggerEvent(Event.WalletUpdate);
};

export const connect = async (provider) => {
  try {
    if (provider === WalletTypes.Metamask) {
      await connectMetamask();
    } else if (provider === WalletTypes.WalletConnect) {
      await connectWalletConnect();
    } else {
      console.error("Unknown wallet provider", provider);
    }
  } catch (e) {
    console.log(e);
    init();
  }
};

export const loginToMagicWithEmail = async (email) => {
  await magic.auth.loginWithMagicLink({
    email,
    showUI: false
  });

  await connectToMagic();
};

export const loginToMagicWithGoogle = async () => {
  await magic.oauth
    .loginWithRedirect({
      provider: "google",
      redirectURI: `${window.location.origin}`
    })
    .catch((error) => {
      console.log("Error while logging in with Gmail: ", error);
    });
};

export const connectToMagic = async () => {
  const provider = new ethers.providers.Web3Provider(magic.rpcProvider);
  await setSigner(provider);
  walletChoiceLocalStorage.set(WalletTypes.Magic);
};

export const connectWalletConnect = async () => {
  const web3 = new WalletConnectProvider({
    infuraId: process.env.VUE_APP_INFURA_ID,
    bridge: "https://polygon.bridge.walletconnect.org"
  });

  disconnectFromWalletConnect = async () => {
    await web3.disconnect();
    window.localStorage.removeItem("walletconnect");
  };

  await web3.enable();
  const provider = new providers.Web3Provider(web3);
  await setSigner(provider);
  addListeners(web3, connectWalletConnect);
  walletChoiceLocalStorage.set(WalletTypes.WalletConnect);
};

export const connectMetamask = async () => {
  const provider = new ethers.providers.Web3Provider(window.ethereum, "any");
  await window.ethereum.request({ method: "eth_requestAccounts" });
  await setSigner(provider);
  addListeners(window.ethereum, connectMetamask);
  walletChoiceLocalStorage.set(WalletTypes.Metamask);
};

const connectDefaultProvider = () => {
  setProvider(
    window.ethereum
      ? new ethers.providers.Web3Provider(window.ethereum, "any")
      : createRpcProvider(process.env.VUE_APP_NETWORK)
  );

  if (window.ethereum) {
    addListeners(window.ethereum, connectDefaultProvider);
  }
};

export const init = async () => {
  const networkChoice = networkChoiceLocalStorage.get();
  const walletChoice = walletChoiceLocalStorage.get();

  initMagic(networkChoice);
  if (queryStringToObject().provider === "google") {
    await magic.oauth.getRedirectResult();
    await connectToMagic();
  } else if (store.state.isLite) {
    if (walletChoice === WalletTypes.Magic) {
      const isLoggedIn = await magic.user.isLoggedIn();
      if (isLoggedIn) {
        await connectToMagic();
      }
    }
  } else {
    if (
      !!window.localStorage.getItem("walletconnect") &&
      walletChoice === WalletTypes.WalletConnect
    ) {
      await connectWalletConnect();
    } else if (window.ethereum && walletChoice === WalletTypes.Metamask) {
      const accounts = await ethereum.request({ method: "eth_accounts" });
      if (accounts.length) {
        await connectMetamask();
      }
    } else if (walletChoice === WalletTypes.Magic) {
      const isLoggedIn = await magic.user.isLoggedIn();
      if (isLoggedIn) {
        await connectToMagic();
      } else {
        walletChoiceLocalStorage.remove();
      }
    } else {
      connectDefaultProvider();
    }
  }

  store.commit("setInitialisingWallet", false);
};

export const disconnect = async () => {
  resetSigner();

  const walletChoice = walletChoiceLocalStorage.get();

  if (walletChoice === WalletTypes.Magic) {
    await magic.user.logout();
  }

  if (disconnectFromWalletConnect) {
    disconnectFromWalletConnect();
    disconnectFromWalletConnect = null;
  }

  walletChoiceLocalStorage.remove();

  if (!store.state.isLite) {
    connectDefaultProvider();
  }
};

const addListeners = (web3, initProvider) => {
  if (currentWeb3) {
    currentWeb3.removeAllListeners(Event.WalletAccountChange);
    currentWeb3.removeAllListeners(Event.WalletChainChange);
    currentWeb3.removeAllListeners(Event.WalletDisconnect);
  }

  web3.on(Event.WalletAccountChange, initProvider);
  web3.on(Event.WalletChainChange, initProvider);
  web3.on(Event.WalletDisconnect, init);

  currentWeb3 = web3;
};

export const switchNetwork = async (network) => {
  const provider = new ethers.providers.Web3Provider(window.ethereum, "any");
  if (!provider?.provider.isMetaMask) return;

  const mmProvider = provider.provider;
  const newNetwork = NETWORK_PARMS[network];
  if (!newNetwork) return;

  try {
    await mmProvider.request({
      method: "wallet_switchEthereumChain",
      params: [{ chainId: newNetwork.chainId }]
    });
  } catch (e) {
    if (e.code === 4902) {
      try {
        await mmProvider.request({
          method: "wallet_addEthereumChain",
          params: [newNetwork]
        });
      } catch (e) {
        console.log("Error adding network:", network, e);
      }
    } else {
      throw new Error(e.message);
    }
  }
};

export const addAssetToWallet = async (token) => {
  const provider = new ethers.providers.Web3Provider(window.ethereum, "any");
  if (!provider?.provider.isMetaMask) return;

  const mmProvider = provider.provider;

  try {
    const assetAdded = await mmProvider.request({
      method: "wallet_watchAsset",
      params: {
        type: "ERC20",
        options: {
          address: token.address,
          symbol: token.symbol,
          decimals: token.decimals,
          image: token.image
        }
      }
    });
  } catch (e) {
    console.log("Error adding asset to wallet:", e);
  }
};
