<template>
  <div :id="id + '-wrapper'">
    <div :class="'hfi-select uk-inline ' + selectClasses">
      <span v-if="!disabled" class="uk-form-icon uk-form-icon-flip">
        <i
          v-if="open"
          class="fal fa-chevron-up fa-icon"
          style="font-size: 1.2rem"
        />
        <i
          v-else
          class="fal fa-chevron-down fa-icon"
          style="font-size: 1.2rem"
        />
      </span>

      <span v-if="useFlags" class="uk-form-icon">
        <gb-flag class="currency-flag" :code="value.slice(0, 2)" size="small" />
      </span>

      <span
        v-else-if="useTokenList && tokenList.length > 0"
        class="uk-form-icon"
      >
        <img
          style="margin-right: 2px"
          height="24"
          width="24"
          :src="
            tokenList.find(
              (tok) =>
                tok.symbol ===
                (shownValue === 'WETH' && showETHForWETH ? 'ETH' : shownValue)
            ).icon
          "
          :alt="shownValue === 'WETH' && showETHForWETH ? 'ETH' : shownValue"
        />
      </span>

      <span v-else-if="icons.length > 0" class="uk-form-icon">
        <img
          height="24"
          width="24"
          :src="
            icons.find((icon) =>
              icon.toLowerCase().includes(shownValue.toLowerCase())
            )
          "
          :alt="shownValue"
        />
      </span>

      <input
        :disabled="disabled"
        :class="'uk-input ' + inputClasses"
        type="button"
        :value="
          values
            ? values.find((val) => val.value === shownValue).name
            : shownValue
        "
      />
    </div>

    <div
      :id="dropdownId"
      class="uk-dropdown uk-width-expand"
      :style="id === 'selectCurrency' ? 'width: 108px;' : ''"
      :uk-dropdown="
        'mode: click; delay-hide: 0;' +
        ' pos: ' +
        position +
        ';' +
        (boundaryClass
          ? ` boundary: .${boundaryClass}; boundary-align: true;`
          : '') +
        ` offset: ${offset};`
      "
    >
      <div v-if="search">
        <form class="uk-search uk-search-default">
          <span class="uk-search-icon-flip">
            <i class="fal fa-search uk-margin-small-left" />
          </span>
          <input
            ref="search"
            class="uk-input uk-search-input"
            type="search"
            placeholder="search"
            v-model="searchValue"
          />
          <a
            class="uk-form-icon uk-form-icon-flip"
            @click.prevent="clearSearch"
          >
            <i
              class="fal fa-times fa-icon"
              style="margin-right: -4px; font-size: 1.2rem"
            />
          </a>
        </form>
      </div>

      <vue-custom-scrollbar
        :settings="vueCustomScrollbarSettings"
        class="scroll-area uk-overflow-auto"
        :style="{ maxHeight: maxHeight === '' ? 'unset' : maxHeight + 'px' }"
      >
        <ul class="uk-nav uk-dropdown-nav handle-select-currency">
          <li
            v-for="(option, i) in sortedOptions.filter((option) =>
              option.toLowerCase().includes(searchValue.toLowerCase())
            )"
            :v-if="option"
            :key="value + '_' + option + '_' + i"
            :class="
              option === value && !unavailable.some((el) => el === value)
                ? 'uk-active'
                : '' + ' uk-width-expand'
            "
          >
            <a
              :class="
                (icons.length > 0 ? 'with-icon' : '') +
                (unavailable.some((el) => el === option) ? ' disabled' : '')
              "
              @click.prevent="
                unavailable.some((el) => el === option)
                  ? undefined
                  : changeValue(option)
              "
              :uk-tooltip="
                unavailable.some((el) => el === option)
                  ? 'title: coming soon; pos: left;'
                  : undefined
              "
            >
              <gb-flag
                v-if="useFlags"
                class="currency-flag-dropdown"
                :code="option.slice(0, 2)"
                size="small"
              />

              <img
                v-else-if="useTokenList && tokenList.length > 0"
                style="margin-right: 2px"
                height="24"
                width="24"
                :src="
                  tokenList.find(
                    (tok) =>
                      tok.symbol ===
                      (option === 'WETH' && showETHForWETH ? 'ETH' : option)
                  ).icon
                "
                :alt="option === 'WETH' && showETHForWETH ? 'ETH' : option"
              />

              <img
                v-else-if="icons.length > 0"
                style="margin-right: 2px"
                height="24"
                width="24"
                :src="
                  icons.find((icon) =>
                    icon
                      .toLowerCase()
                      .includes(
                        (option === 'WETH' && showETHForWETH
                          ? 'ETH'
                          : option
                        ).toLowerCase()
                      )
                  )
                "
                :alt="option === 'WETH' && showETHForWETH ? 'ETH' : option"
              />
              {{
                values
                  ? values.find(
                      (val) =>
                        val.value ===
                        (option === "WETH" && showETHForWETH ? "ETH" : option)
                    ).name
                  : option === "WETH" && showETHForWETH
                  ? "ETH"
                  : option
              }}
            </a>
          </li>
        </ul>
      </vue-custom-scrollbar>
    </div>
  </div>
</template>

<script>
import UIkit from "uikit";
import vueCustomScrollbar from "vue-custom-scrollbar";
import "vue-custom-scrollbar/dist/vueScrollbar.css";
import { store } from "@/store";

export default {
  name: "Select",
  props: {
    id: { type: String, default: "" },
    boundaryClass: { type: String, default: "" },
    selectClasses: { type: String, default: "" },
    inputClasses: { type: String, default: "" },
    disabled: { type: Boolean, default: true },
    value: { type: String, default: "" },
    options: { type: Array, default: () => [] },
    icons: { type: Array, default: () => [] },
    values: { type: Array },
    useFlags: { type: Boolean, default: false },
    unavailable: { type: Array, default: () => [] },
    showETHForWETH: { type: Boolean, default: true },
    position: { type: String, default: "bottom-justify" },
    search: { type: Boolean, default: false },
    sortOptions: { type: Boolean, default: false },
    maxHeight: { type: String, default: "" },
    offset: { type: String, default: "-2" },
    useTokenList: { type: Boolean, default: false },
  },
  components: {
    vueCustomScrollbar,
  },

  data() {
    return {
      open: false,
      dropdownId: this.id + "-dropdown",
      shownValue:
        this.value === "WETH" && this.showETHForWETH ? "ETH" : this.value,
      searchValue: "",
      sortedOptions: this.options,
      vueCustomScrollbarSettings: {
        suppressScrollY: false,
        suppressScrollX: true,
        wheelPropagation: true,
        minScrollbarLength: 15,
        wheelSpeed: 0.125,
      },
    };
  },

  beforeMount() {
    this.sort();
  },

  beforeUpdate() {
    this.shownValue =
      this.value === "WETH" && this.showETHForWETH ? "ETH" : this.value;

    this.sort();
  },

  mounted() {
    this.shownValue =
      this.value === "WETH" && this.showETHForWETH ? "ETH" : this.value;
    UIkit.util.on("#" + this.dropdownId + ".uk-dropdown", "show", () => {
      this.open = true;
      if (this.search) this.$refs.search.focus;
    });
    UIkit.util.on("#" + this.dropdownId + ".uk-dropdown", "hide", () => {
      this.open = false;
    });
  },

  computed: {
    tokenList() {
      return store.state.tokenList;
    },
  },

  watch: {
    value(newValue) {
      this.changeValue(newValue);
    },
  },
  methods: {
    changeValue(option) {
      UIkit.dropdown("#" + this.dropdownId + ".uk-dropdown").hide();
      if (!this.unavailable.some((el) => el === option)) {
        this.shownValue =
          option === "WETH" && this.showETHForWETH ? "ETH" : option;
        this.searchValue = "";
        this.$emit("change", option);
      }
    },
    clearSearch() {
      this.searchValue = "";
    },
    sort() {
      const sortedUnavailable =
        this.sortOptions && this.unavailable.length > 0
          ? this.unavailable
              .filter((option) => this.options.includes(option))
              .sort((a, b) => (a.toLowerCase() > b.toLowerCase() ? 1 : -1))
          : this.unavailable.filter((option) => this.options.includes(option));
      const sortedOptions = this.sortOptions
        ? this.options
            .filter((option) => !sortedUnavailable.includes(option))
            .sort((a, b) => (a.toLowerCase() > b.toLowerCase() ? 1 : -1))
        : this.options;
      this.sortedOptions = sortedOptions.concat(
        this.sortOptions ? sortedUnavailable : []
      );
    },
  },
};
</script>

<style lang="scss" scoped>
@use "src/assets/styles/handle.fi" as handle;

.uk-input:disabled {
  cursor: default;
}

.uk-dropdown {
  padding: 0;
}

.uk-dropdown-nav > li > a {
  padding-left: 10px;
}

.uk-form-icon {
  color: inherit;
}

.with-icon {
  padding-left: 6px !important;
}

.currency-flag {
  width: 20px;
  height: 15px;
  margin-top: 2px;
}

.currency-flag-dropdown {
  width: 20px;
  height: 15px;
  margin-top: -3px;
}

.uk-search {
  width: 100%;
  display: flex;
  align-items: center;
}

.uk-search-input {
  cursor: unset;
}

.uk-search-icon-flip {
  margin-right: 8px !important;
}

.uk-form-icon:hover,
.hfi-select:hover {
  i {
    color: handle.$green;
  }
}
</style>
