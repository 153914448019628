import { getRevertReasonFromErrorMessage } from "../utils/getRevertReason";
import Code from "./RevertErrorCode";

const Message = {
  [Code.IZ]: "Invalid input of address zero",
  [Code.IA]: "Invalid input amount",
  [Code.IF]: "Invalid input fxToken address",
  [Code.IC]: "Invalid input collateral token address",
  [Code.CR]: "Invalid resulting vault collateral ratio",
  [Code.CA]: "Invalid resulting collateral amount",
  [Code.WE]: "wETH is not accepted",
  [Code.NP]: "Not payable",
  [Code.FT]: "ERC20 transfer failed",
  [Code.FP]: "Ether payment failed",
  [Code.NO]: "Caller is not an operator/owner",
  [Code.NA]: "Caller is not an admin",
  [Code.NW]: "Caller is not whitelisted nor operator",
  [Code.VL]: "Vault is under liquidation"
};

/**
 * Returns whether the error message should be formatted by this module.
 * @param {String} message
 */
const Filter = (message) => {
  return Object.keys(Code).includes(message);
};

/**
 * Attempts to format the message into an error message.
 * @param {String} message
 */
const Format = async (message) => {
  const mapped = Message[message];
  if (mapped != null) return mapped;
  const code = await getRevertReasonFromErrorMessage(message);
  return Message[code];
};

export default {
  Code,
  Message,
  Filter,
  Format
};
