<template>
  <div :id="id">
    <div :id="id + 'Titles'" class="uk-text-right">
      <div>
        <h3
          class="uk-margin-remove-bottom uk-margin-small-top uk-flex-right"
          v-if="fromToken.symbol && toToken.symbol"
        >
          <img
            class="uk-position-relative hfi-token-overlap"
            style="margin-top: -5px; border-radius: 50%; z-index: 1"
            width="32"
            :src="fromToken.icon"
            :alt="fromToken.symbol"
            @error="iconError"
          />
          <img
            class="uk-position-relative"
            style="margin-top: -5px; margin-left: -12px"
            width="24"
            :src="toToken.icon"
            :alt="toToken.symbol"
            @error="iconError"
          />
          {{ fromToTokens }}
        </h3>

        <div
          v-if="!loading && !fxTokenDataError && !disabled"
          class="uk-flex uk-flex-right uk-flex-top"
        >
          <Roller
            class="uk-text-right uk-h4"
            :style="`color: ${priceMovement < 0 ? '#ff7bac' : 'a9e2b0'}`"
            :key="hoverPrice"
            :defaultChar="
              prevHoverPrice.toFixed(
                7 - digitCount(chartData[chartData.length - 1].price)
              )
            "
            :text="
              hoverPrice.toFixed(
                7 - digitCount(chartData[chartData.length - 1].price)
              )
            "
            :isNumberFormat="hoverPrice > 1 ? true : undefined"
            :transition="0.6"
          />
          <p
            class="uk-text-right uk-h4 uk-margin-remove-top"
            style="margin-bottom: -12px"
          >
            <span
              :key="priceMovement"
              :style="`color: ${priceMovement < 0 ? '#ff7bac' : 'a9e2b0'}`"
            >
              ({{ priceMovement >= 0 ? "+" : ""
              }}{{
                ((priceMovement / chartData[0].price) * 100).toLocaleString(
                  undefined,
                  digits(2)
                )
              }}%)
            </span>
            <span
              class="cursor-pointer"
              uk-tooltip="title: prices provided by coingecko; pos: right;"
            >
              <img
                width="20"
                style="margin-top: -4px"
                src="/coinGeckoLogo.png"
                alt="coingecko"
              />
            </span>
          </p>
        </div>
      </div>
    </div>

    <div :id="id + 'Svg'"></div>

    <div
      v-if="loading || fxTokenDataError"
      class="uk-flex uk-flex-center uk-flex-middle uk-height-1-1"
      style="height: 410px"
    >
      <img
        src="handle.fiDancingGorilla.gif"
        style="margin: -12px 0"
        class="uk-margin-right"
        alt="handle.fi dancing gorilla"
        width="50"
      />
      <p class="uk-h3 uk-margin-remove">
        <span v-if="!account"> connect wallet to view price data </span>
        <span v-else-if="fxTokenDataError">
          no data for this date range,
          <br />
          please select another
        </span>
        <span v-else-if="loading"> loading chart data </span>
        <span v-else> no price data available </span>
      </p>
    </div>

    <div
      :id="id + 'Buttons'"
      :key="days"
      class="uk-flex uk-flex-right"
      :style="`${loading ? 'opacity: 0;' : ''}`"
      v-if="!disabled"
    >
      <div class="uk-button-group">
        <button
          @click.prevent="setChartPeriod(1)"
          :class="`uk-button uk-button-primary uk-button-small ${
            days === 1 ? 'uk-active' : 'cursor-pointer'
          }`"
          style="border-right-width: 0 !important"
          :disabled="days === 1"
        >
          1d
        </button>
        <button
          @click.prevent="setChartPeriod(7)"
          :class="`uk-button uk-button-primary uk-button-small ${
            days === 7 ? 'uk-active' : 'cursor-pointer'
          }`"
          style="border-right-width: 0 !important"
          :disabled="days === 7"
        >
          1w
        </button>
        <button
          @click.prevent="setChartPeriod(31)"
          :class="`uk-button uk-button-primary uk-button-small ${
            days === 31 ? 'uk-active' : 'cursor-pointer'
          }`"
          :disabled="days === 31"
        >
          1m
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { getTokenPriceData, getNativeTokenPriceData } from "@/utils/coingecko";
import { format } from "date-fns";
import Token from "@/types/Token";
import * as d3 from "d3";
import Roller from "vue-roller";
import { store } from "@/store";
import { isNativeToken } from "@/utils/utils";
import { NETWORK_NAMES } from "@/utils/constants/networks";
import { getPriceData } from "@/utils/prices";

export default {
  name: "PriceChart",
  components: {
    Roller,
  },
  props: {
    id: { type: String, default: "chart" },
    disabled: { type: Boolean },
    pricesNetwork: { type: String, default: "" },
    fromToken: { type: Object, default: () => {} },
    toToken: { type: Object, default: () => {} },
  },

  data() {
    return {
      chartConfig: {
        date: {
          key: "date",
          inputFormat: "%Y-%m-%d_%H:%M:%S",
          outputFormat: "%d/%m %H:%M",
        },
        size: {
          width: 500,
          height: 360,
        },
        color: {
          key: false,
          keys: false,
          scheme: false,
          current: "#a9e2b0",
          default: "#a9e2b0",
        },
        margin: {
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
        },
      },
      days: 1,
      chartData: [],
      digitCount(num) {
        if (num === 0) return 1;
        return Math.floor(Math.log10(Math.abs(num))) + 1;
      },
      dataRefreshIntervalId: null,
      fromTokenChange: false,
      toTokenChange: false,
      prevHoverPrice: 0,
      hoverPrice: 0,
      tokenList: [],
      NETWORK_NAMES,
      refreshChart: false,
      fxTokenDataError: false,
    };
  },

  async mounted() {
    await this.buildChart();
  },

  beforeDestroy() {
    if (!this.dataRefreshIntervalId) return;
    clearInterval(this.dataRefreshIntervalId);
  },

  computed: {
    loading() {
      return (
        !this.fromToken ||
        !this.toToken ||
        (this.chartData.length === 0 && !this.fxTokenDataError)
      );
    },
    network() {
      return store.state.network;
    },
    account() {
      return store.state.account;
    },
    pricesNetworkName() {
      return this.pricesNetwork ? this.pricesNetwork.split(" ")[0] : "";
    },
    fromToTokens() {
      return this.fromToken && this.toToken
        ? `${this.fromToken.symbol}/${this.toToken.symbol}`
        : null;
    },
    priceMovement() {
      return this.chartData.length === 0
        ? 0
        : this.hoverPrice - this.chartData[0].price;
    },
    chartColour() {
      return this.priceMovement < 0
        ? "#ff7bac"
        : this.chartConfig.color.default;
    },
  },

  watch: {
    disabled() {
      if (this.disabled) {
        const svg = d3.select("#" + this.id + "Svg");
        svg.selectAll("*").remove();
      }
    },

    async network(val, oldVal) {
      if (this.fromTokenChange || this.toTokenChange) return;
      await this.buildChart();
    },

    async fromToken(val, oldVal) {
      if (!val || this.toTokenChange) return;

      if (val.address === oldVal.address) return;

      this.fromTokenChange = true;
      this.fxTokenDataError = false;

      await this.buildChart();
    },

    async toToken(val, oldVal) {
      if (!val || this.fromTokenChange) return;

      if (
        val.address === oldVal.address &&
        val.pricesAddress === oldVal.pricesAddress
      )
        return;

      this.toTokenChange = true;
      this.fxTokenDataError = false;

      await this.buildChart();
    },
    deep: true,
  },

  methods: {
    isFxToken(token) {
      return token.startsWith("fx") && token.length === 5;
    },

    async buildChart() {
      if (!this.fromToken || !this.toToken) return;

      if (this.dataRefreshIntervalId) clearInterval();

      await this.getData();

      if (!this.dataRefreshIntervalId)
        this.dataRefreshIntervalId = setInterval(
          () => this.dataRefreshIteration(),
          60000
        );
    },

    async getData() {
      if (!this.fromToken.address || !this.toToken.address) return;

      if (!this.refreshChart) {
        this.chartData = [];
        this.removeChart();
      }

      this.fxTokenDataError = false;

      const priceDataPromise = (token) =>
        isNativeToken(token.symbol)
          ? getNativeTokenPriceData(token.symbol, "USD", this.days)
          : this.isFxToken(token.symbol)
          ? getPriceData(token.symbol.slice(-3), "USD", this.days)
          : getTokenPriceData(
              this.pricesNetworkName,
              token.address,
              "USD",
              this.days
            );

      try {
        let [fromTokenPriceData, toTokenPriceData] = await Promise.all([
          priceDataPromise(this.fromToken),
          priceDataPromise(this.toToken),
        ]);

        if (this.isFxToken(this.fromToken.symbol)) {
          if (
            fromTokenPriceData.status === "error" ||
            !fromTokenPriceData.values
          ) {
            this.fxTokenDataError = true;
            if (this.dataRefreshIntervalId) clearInterval();
          } else {
            this.fxTokenDataError = false;

            fromTokenPriceData.prices = fromTokenPriceData.values
              .sort((a, b) => (a.datetime > b.datetime ? 1 : -1))
              .map((priceData) => {
                return [
                  Number(format(new Date(priceData.datetime), "T")),
                  (Number(priceData.close) + Number(priceData.open)) / 2,
                ];
              });
          }
        }

        if (this.isFxToken(this.toToken.symbol)) {
          if (toTokenPriceData.status === "error" || !toTokenPriceData.values) {
            this.fxTokenDataError = true;
            if (this.dataRefreshIntervalId) clearInterval();
          } else {
            this.fxTokenDataError = false;

            toTokenPriceData.prices = toTokenPriceData.values
              .sort((a, b) => (a.datetime > b.datetime ? 1 : -1))
              .map((priceData) => {
                return [
                  Number(format(new Date(priceData.datetime), "T")),
                  (Number(priceData.close) + Number(priceData.open)) / 2,
                ];
              });
          }
        }

        if (this.fxTokenDataError) return;

        const mappedFromTokenDates = fromTokenPriceData.prices.map(
          (pricePoint) => pricePoint[0]
        );

        const mappedToTokenPriceData = toTokenPriceData.prices.map(
          (pricePoint) => {
            return {
              date: format(new Date(pricePoint[0]), "yyyy-MM-dd'_'HH:mm:ss"),
              price:
                fromTokenPriceData.prices.find(
                  (fromPricePoint) =>
                    fromPricePoint[0] ===
                    this.closestPrice(mappedFromTokenDates, pricePoint[0])
                )[1] / pricePoint[1],
            };
          }
        );
        this.chartData = mappedToTokenPriceData;
        this.prevHoverPrice = this.hoverPrice;
        this.hoverPrice = this.chartData[this.chartData.length - 1].price;

        await this.renderChart();
      } catch (e) {
        console.error("Get prices error:", e);
        return;
      }
    },

    async renderChart() {
      const data = this.chartData;
      const margin = this.chartConfig.margin;
      const width =
        document.querySelector("#" + this.id).offsetWidth -
        margin.left -
        margin.right;
      const height = this.chartConfig.size.height;

      // parse the date / time
      const parseTime = d3.timeParse(this.chartConfig.date.inputFormat);

      // set the ranges
      const x = d3.scaleTime().range([0, width]);
      const y = d3.scaleLinear().range([height, 0]);

      // define the area
      const area = d3
        .area()
        .x(function (d) {
          return x(d.date);
        })
        .y0(height)
        .y1(function (d) {
          return y(d.price);
        });

      // define the line
      const valueline = d3
        .line()
        .x(function (d) {
          return x(d.date);
        })
        .y(function (d) {
          return y(d.price);
        });

      this.removeChart();

      const svg = d3
        .select("#" + this.id + "Svg")
        .append("svg")
        .attr("width", width + margin.left + margin.right)
        .attr("height", height - margin.top - margin.bottom)
        .append("g")
        .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

      // format the data
      let minPrice = 9999999999;
      let maxPrice = 0;
      data.forEach(function (d) {
        d.date = parseTime(d.date);
        d.price = +d.price;
        if (d.price < minPrice) minPrice = d.price;
        else if (d.price > maxPrice) maxPrice = d.price;
      });
      const scaleFactor = (maxPrice - minPrice) * 0.1;

      // scale the range of the data
      x.domain(
        d3.extent(data, function (d) {
          return d.date;
        })
      );
      y.domain([
        d3.min(data, function (d) {
          return d.price - scaleFactor;
        }),
        d3.max(data, function (d) {
          return d.price + scaleFactor;
        }),
      ]);

      // set the gradient
      svg
        .append("linearGradient")
        .attr("id", "area-gradient")
        .attr("gradientUnits", "userSpaceOnUse")
        .attr("x1", 0)
        .attr(
          "y1",
          y(
            d3.min(data, function (d) {
              return d.price - scaleFactor;
            })
          )
        )
        .attr("x2", 0)
        .attr(
          "y2",
          y(
            d3.max(data, function (d) {
              return d.price + scaleFactor;
            })
          )
        )
        .selectAll("stop")
        .data([
          { offset: "0%", color: "transparent" },
          { offset: "100%", color: this.chartColour },
        ])
        .enter()
        .append("stop")
        .attr("offset", function (d) {
          return d.offset;
        })
        .attr("stop-color", function (d) {
          return d.color;
        });

      // add the area
      svg
        .append("path")
        .data([data])
        .attr("class", "area")
        .attr("d", area)
        .on("mouseover", (event, d) => mouseover(event, d, this))
        .on("mousemove", (event, d) => mousemove(event, d, this))
        .on("mouseout", (event, d) => mouseleave(event, d, this));

      // add the valueline path.
      svg
        .append("path")
        .data([data])
        .attr("class", "line")
        .attr("d", valueline)
        .style("fill", "none")
        .style("stroke", this.chartColour);

      const tooltip = d3
        .select("#" + this.id)
        .append("div")
        .style("opacity", 0)
        .attr("class", "tooltip")
        .style("position", "absolute")
        .style("color", this.chartConfig.color.default)
        .style("border", "1px solid #a9e2b0")
        .style("background-color", "#2a313e")
        .style("padding", "4px 8px");

      const bisect = d3.bisector(function (d) {
        return d.date;
      }).left;

      this.fromTokenChange = false;
      this.toTokenChange = false;

      function mouseover(event, d) {}

      function mousemove(event, d, _this) {
        let x0 = x.invert(d3.pointer(event)[0]);
        let i = bisect(data, x0, 1);
        _this.prevHoverPrice = _this.hoverPrice;
        _this.hoverPrice = data[i].price;

        tooltip
          .html(`${format(data[i].date, "d/M HH:mm")}`)
          .attr("class", "tooltip active")
          .style("opacity", 1)
          .style("left", width * 1.85 + d3.pointer(event)[0] + "px")
          // It is important to put the +95 otherwise the tooltip is exactly where the point is and it creates a weird effect
          .style(
            "top",
            d3.pointer(event)[1] +
              document.querySelector(".tooltip.active").offsetHeight +
              95 +
              "px"
          );
      }

      function mouseleave(event, d, _this) {
        tooltip.style("opacity", 0);
        _this.prevHoverPrice = _this.hoverPrice;
        _this.hoverPrice = data[data.length - 1].price;
      }
    },

    removeChart() {
      d3.selectAll("#" + this.id + "Svg svg").remove();
      d3.selectAll(".tooltip").remove();
    },

    dataRefreshIteration() {
      if (!this.dataRefreshIntervalId) return;
      this.refreshChart = true;
      this.buildChart();
      this.refreshChart = false;
    },

    closestPrice(array, goal) {
      return array.reduce((prev, curr) => {
        return Math.abs(curr - goal) < Math.abs(prev - goal) ? curr : prev;
      });
    },

    digits(minDigits, maxDigits = minDigits) {
      return {
        minimumFractionDigits: minDigits,
        maximumFractionDigits: maxDigits,
      };
    },

    async setChartPeriod(days) {
      this.days = days;
      this.buildChart();
    },

    iconError(event) {
      if (event.target.src.includes("amazonaws"))
        event.target.src = "handle.fiTokenPlaceholder.png";
      else {
        const token = this.tokenList.find(
          (t) =>
            t.symbol ===
            (event.target.alt === Token.ETH ? Token.WETH : event.target.alt)
        );

        if (token) {
          event.target.src =
            "https://token-icons.s3.amazonaws.com/" + token.address + ".png";
        }
      }
    },
  },
};
</script>

<style lang="scss">
@use "src/assets/styles/handle.fi" as handle;

svg text {
  font-family: handle.$font;
}

svg.homestead-color {
  .eth-fill-1 {
    fill: handle.$homestead-color !important;
  }
}

svg.ropsten-color {
  .eth-fill-1 {
    fill: handle.$ropsten-color !important;
  }
}

svg.kovan-color {
  .eth-fill-1 {
    fill: handle.$kovan-color !important;
  }
}

svg.rinkeby-color {
  .eth-fill-1 {
    fill: handle.$rinkeby-color !important;
  }
}

svg.goerli-color {
  .eth-fill-1 {
    fill: handle.$goerli-color !important;
  }
}

.area {
  fill: url(#area-gradient);
}
</style>
