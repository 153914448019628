import axios from "axios";
import { store } from "@/store";
import NetworkType from "@/types/Network";
import TokenType from "@/types/Token";

const NETWORK_TO_COINGECKO_NETWORK_MAP = {
  [NetworkType.homestead]: "ethereum",
  [NetworkType.polygon]: "polygon-pos",
  [NetworkType.arbitrum]: "arbitrum-one"
};

const getCoinGeckoNetworkName = (network) =>
  NETWORK_TO_COINGECKO_NETWORK_MAP[network || store.state.network] ||
  "ethereum";

const getNativeTokenId = (token) => {
  switch (token) {
    case TokenType.MATIC:
      return "matic-network";
    default:
      return "ethereum";
  }
};

export const getTokenList = async () => {
  try {
    const network = getCoinGeckoNetworkName();
    const { data } = await axios.get(
      `https://api.coingecko.com/api/v3/coins/list?include_platform=true`
    );
    return data.filter(
      (token) =>
        token.platforms.ethereum &&
        token.platforms.ethereum !== "" &&
        token.platforms[network] &&
        token.platforms[network] !== ""
    );
  } catch (e) {
    return;
  }
};

export const getToken = async (address, network) => {
  const n = getCoinGeckoNetworkName(network);

  try {
    const { data } = await axios.get(
      `https://api.coingecko.com/api/v3/coins/${n}/contract/${address}`
    );
    return data;
  } catch (e) {
    return;
  }
};

export const getTokenPrice = async (address, network) => {
  try {
    const data = await getToken(address, network);
    return data?.market_data?.current_price?.usd;
  } catch (e) {
    console.log(e);
  }
};

export const getTokenPriceData = async (network, address, fiat, days = 1) => {
  const coingeckoNetworkName = getCoinGeckoNetworkName(network);
  try {
    const { data } = await axios.get(
      `https://api.coingecko.com/api/v3/coins/${coingeckoNetworkName}/contract/${address}/market_chart?vs_currency=${fiat.toLowerCase()}&days=${days}`
    );
    return data;
  } catch (e) {
    if (network !== NetworkType.homestead)
      try {
        const { data } = await axios.get(
          `https://api.coingecko.com/api/v3/coins/ethereum/contract/${address}/market_chart?vs_currency=${fiat.toLowerCase()}&days=${days}`
        );
        return data;
      } catch (e) {
        return;
      }
  }
};

export const getNativeTokenPriceData = async (token, fiat, days = 1) => {
  try {
    const { data } = await axios.get(
      `https://api.coingecko.com/api/v3/coins/${getNativeTokenId(
        token
      )}/market_chart?vs_currency=${fiat.toLowerCase()}&days=${days}`
    );
    return data;
  } catch (e) {
    return;
  }
};
