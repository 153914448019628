import { getRevertReasonFromErrorMessage } from "../../utils/getRevertReason";
import {
  showNotification,
  closeAllNotifications,
  getExplorerMeta
} from "@/utils/utils";
import { store } from "@/store";
import formatError from "@/utils/formatError";
import { signer } from "@/utils/wallet";

export const listenForConfirmations = async (
  receipt,
  customWaitingForTransactionApprovalMessage,
  customMiningMessage,
  customSuccessMessage
) => {
  try {
    closeAllNotifications();

    const explorerMeta = await getExplorerMeta(receipt.hash, "tx");

    showNotification(
      "success",
      customMiningMessage ||
        `transaction processing, please wait... ${explorerMeta.typeMessageWithLinkShort}`,
      undefined,
      0
    );

    const transaction = await receipt.wait(1);

    closeAllNotifications();

    showNotification(
      "success",
      customSuccessMessage
        ? await customSuccessMessage(
            transaction,
            explorerMeta.typeMessageWithLink
          )
        : `transaction successful. ${explorerMeta.typeMessageWithLink}`,
      undefined,
      0
    );

    return transaction;
  } catch (error) {
    console.error(error);
    const reason =
      (await getRevertReasonFromErrorMessage(error)) ?? error.message;
    throw new Error(await formatError(reason));
  }
};

export default async (
  tx,
  customWaitingForTransactionApprovalMessage,
  customMiningMessage,
  customSuccessMessage
) => {
  if (store.state.isLite) {
    showNotification("success", `submitting transaction...`, undefined, 0);
  } else {
    showNotification(
      "warning",
      customWaitingForTransactionApprovalMessage ||
        `waiting for transaction approval...`,
      undefined,
      0
    );
  }

  let receipt;
  try {
    receipt = await signer.sendTransaction(tx);
  } catch (error) {
    console.error(error);
    const reason =
      (await getRevertReasonFromErrorMessage(error)) ?? error.message;
    throw new Error(await formatError(reason));
  }

  return listenForConfirmations(
    receipt,
    customWaitingForTransactionApprovalMessage,
    customMiningMessage,
    customSuccessMessage
  );
};
