<template>
  <Network :network="network" :state="state" />
</template>

<script>
import { store } from "@/store";
import Network from "@/components/Network";

export default {
  name: "CurrentNetwork",
  components: {
    Network,
  },
  computed: {
    network() {
      return store.state.network;
    },
    state() {
      return store.state.initialisingWallet
        ? "connecting"
        : !store.state.account
        ? "disconnected"
        : undefined;
    },
  },
};
</script>
