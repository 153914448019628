<template>
  <div class="uk-container uk-container-xlarge hfi-dashboard-container">
    <vue-headful
      description="handle.fi dashboard."
      image="src/assets/logo.png"
      :title="`${isLite ? 'handleLite' : 'handle.fi'} | dashboard`"
    />

    <div>
      <h2 class="uk-margin-small-bottom uk-flex-0">
        {{ this.$options.name.toLowerCase() }}
      </h2>

      <div class="dashboard">
        <ul class="uk-tab-bottom" uk-tab>
          <li :class="dashFn === 'wallet' ? 'uk-active' : ''">
            <a href="" @click.prevent="setDashFn('wallet')">wallet</a>
          </li>
          <li
            v-if="network === Network.arbitrum"
            :class="`${
              account && vaultsStatus
                ? vaultsStatus === 'warning'
                  ? 'hfi-warning'
                  : 'hfi-danger'
                : ''
            } ${dashFn === 'vaults' ? 'uk-active' : ''}
            `"
          >
            <a
              href=""
              @click.prevent="setDashFn('vaults')"
              :uk-tooltip="`title: ${
                vaultsStatus === 'warning'
                  ? 'a vault is below its min. CR'
                  : vaultsStatus === 'danger'
                  ? 'a vault is at risk of liquidation'
                  : 'all vaults are healthy'
              }; pos: bottom;`"
            >
              <i v-if="vaultsLoading" class="fal fa-sync fa-spin" />
              <i
                v-else-if="account && vaultsStatus === 'danger'"
                class="fal fa-ban"
              />
              <i
                v-else-if="account && vaultsStatus === 'warning'"
                class="fal fa-exclamation-triangle"
              />
              <i v-else-if="account" class="fal fa-check-circle" />
              vaults
            </a>
          </li>
          <li :class="dashFn === 'transactions' ? 'uk-active' : ''">
            <a href="" @click.prevent="setDashFn('transactions')"
              >transactions</a
            >
          </li>
        </ul>
        <ul class="uk-switcher">
          <li :class="dashFn === 'wallet' ? 'uk-active' : ''">
            <Wallet @loaded="walletLoaded" />
          </li>
          <li
            v-if="network === Network.arbitrum"
            :class="dashFn === 'vaults' ? 'uk-active' : ''"
          >
            <Vaults @loaded="vaultsLoaded" />
          </li>
          <li :class="dashFn === 'transactions' ? 'uk-active' : ''">
            <Transactions @loaded="transactionsLoaded" />
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import {
  closeAllNotifications,
  showNotification,
  showUniqueNotification,
} from "../utils/utils";
import { store } from "@/store";
import Network from "@/types/Network";

const Wallet = () => ({
  component: import("@/components/Wallet.vue"),
});

const Vaults = () => ({
  component: import("@/components/Vaults.vue"),
});

const Transactions = () => ({
  component: import("@/components/Transactions.vue"),
});

export default {
  name: "Dashboard",
  components: {
    Wallet,
    Vaults,
    Transactions,
  },
  data() {
    return {
      walletLoading: true,
      vaultsLoading: true,
      transactionsLoading: true,
      Network,
    };
  },

  async mounted() {
    await this.resetState();
  },

  beforeDestroy() {
    closeAllNotifications();
  },

  computed: {
    network() {
      return store.state.network;
    },
    account() {
      return store.state.account;
    },
    vaultsStatus() {
      return !this.vaultsLoading && store.state.vaultsStatus;
    },
    network() {
      return store.state.network;
    },
    isLite() {
      return store.state.isLite;
    },
    dashFn() {
      if (
        this.network !== Network.arbitrum &&
        (store.state.dashFn === "vaults" ||
          localStorage.getItem("dashFn") === "vaults")
      )
        this.setDashFn("wallet");
      return store.state.dashFn || localStorage.getItem("dashFn");
    },
  },

  watch: {
    async network() {
      await this.resetState();
    },

    async account() {
      await this.resetState();
    },

    walletLoading: function () {
      if (this.walletLoading || this.vaultsLoading || this.transactionsLoading)
        return;
      closeAllNotifications();
    },

    vaultsLoading: function () {
      if (
        (this.walletLoading ||
          this.vaultsLoading ||
          this.transactionsLoading) &&
        this.network === Network.arbitrum
      )
        return;
      closeAllNotifications();
    },

    transactionsLoading: function () {
      if (this.walletLoading || this.vaultsLoading || this.transactionsLoading)
        return;
      closeAllNotifications();
    },
  },

  methods: {
    resetState: async function () {
      if (
        this.$route.meta.networks &&
        !this.$route.meta.networks?.includes(this.network)
      )
        return;

      if (this.account) {
        showUniqueNotification(
          "gorilla",
          "loading dashboard data, please wait",
          undefined,
          0
        );
        this.showDashboard();
      } else showNotification("success", "please connect wallet", undefined, 0);

      this.setDashFn(this.dashFn);
    },

    showDashboard: function () {
      this.walletLoading = true;
      this.vaultsLoading = this.network === Network.arbitrum;
      this.transactionsLoading = true;
    },

    walletLoaded() {
      this.walletLoading = false;
    },
    vaultsLoaded() {
      this.vaultsLoading = false;
    },
    transactionsLoaded() {
      this.transactionsLoading = false;
    },
    setDashFn(fn) {
      store.commit("setDashFn", fn);
    },
  },
};
</script>

<style lang="scss" scoped></style>
