<template>
  <div id="app" class="uk-height-viewport uk-flex">
    <div id="nav" class="uk-position-fixed uk-position-top uk-width-1-1">
      <AppNavbar :isShop="isShop" />
    </div>
    <LiteLanding v-if="showLiteLanding" />
    <router-view v-else />
    <AppFooter />
    <NetworkModal v-if="networks.length > 0" />
  </div>
</template>

<script>
// @ is an alias to /src
import AppNavbar from "@/components/AppNavbar";
import AppFooter from "@/components/AppFooter";
import NetworkModal from "@/components/NetworkModal";
import LiteLanding from "@/components/LiteLanding";
import { store } from "@/store";

export default {
  name: "Home",
  components: {
    AppNavbar,
    AppFooter,
    NetworkModal,
    LiteLanding,
  },
  computed: {
    isShop() {
      return this.$route.name === "Shop";
    },
    network() {
      return store.state.network;
    },
    networks() {
      return this.$route.meta.networks || [];
    },
    showLiteLanding() {
      return (
        store.state.isLite &&
        (!store.state.account || store.state.initialisingWallet)
      );
    },
  },
  beforeCreate: function () {
    if (window.location.href === "https://store.handle.fi/") {
      this.$router.push("shop");
    }
  },
};
</script>

<style lang="scss">
@use "@/assets/styles/handle.fi" as handle;
#app {
  text-align: -webkit-left;
  background-color: handle.$background;
  padding-top: 100px;
  padding-bottom: 60px;
  overflow-x: hidden;
  min-height: 100vh;
}

@media (orientation: landscape) and (max-width: 1199px) {
  #app {
    padding-top: 97px;
  }
}

@media (orientation: landscape) and (max-width: 959px) {
  #app {
    padding-top: 80px;
  }
}

@media (orientation: portrait) and (max-width: 639px) {
  #app {
    padding-top: 80px;
  }
}
</style>
