<template>
  <div>
    <div class="uk-form-controls">
      <div ref="slippage-wrapper" class="slippage">
        <div ref="slippage-bar" class="bar">
          <div class="green" :style="{ flex: greenFlexSize() }"></div>
          <div class="yellow" :style="{ flex: yellowFlexSize() }"></div>
          <div class="orange" :style="{ flex: orangeFlexSize() }"></div>
          <div class="red" :style="{ flex: redFlexSize() }"></div>
          <div
            class="needle"
            v-bind:class="{ interactive: !isReadOnly }"
            :style="{
              left: `${100 * getPositionalSlippage()}%`,
            }"
          >
            <div class="pointer"></div>
            <div class="data">{{ getSlippagePercentage() }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import getMouseDown from "../utils/getMouseDown";
import getAbsoluteElementPosition from "../utils/getAbsoluteElementPosition";

export default {
  name: "SlippageSlider",
  props: [
    "targetSlippage",
    "minDisplaySlippage",
    "maxDisplaySlippage",
    "onChange",
    "isReadOnly",
    "minSliderSlippage",
    "maxSliderSlippage",
    "maxSafeSlippage",
    "maxValidSlippage",
    "maxRiskSlippage",
  ],
  data() {
    return {
      slippage: 0,
    };
  },
  watch: {
    targetSlippage: {
      immediate: true,
      handler(newValue) {
        this.slippage = newValue;
      },
    },
  },
  mounted() {
    this.registerDragSlippageListener();
  },
  beforeDestroy() {
    this.unregisterDragSlippageListener();
  },
  methods: {
    getRelativeVisibleSlippage(value) {
      return value / (this.maxDisplaySlippage - this.minDisplaySlippage);
    },
    redFlexSize() {
      return this.getRelativeVisibleSlippage(
        this.maxDisplaySlippage - this.maxRiskSlippage
      );
    },
    orangeFlexSize() {
      return this.getRelativeVisibleSlippage(
        this.maxRiskSlippage - this.maxValidSlippage
      );
    },
    yellowFlexSize() {
      return this.getRelativeVisibleSlippage(
        this.maxValidSlippage - this.maxSafeSlippage
      );
    },
    greenFlexSize() {
      return this.getRelativeVisibleSlippage(
        this.maxSafeSlippage - this.minDisplaySlippage
      );
    },
    /**
     * Returns the collateral slippage to be used in screen-space for
     * positioning the CR slider.
     */
    getPositionalSlippage() {
      let value = this.slippage || 0;
      // Clamp value.
      if (value > this.maxDisplaySlippage) value = this.maxDisplaySlippage;
      if (value < this.minDisplaySlippage) value = this.minDisplaySlippage;
      return this.getRelativeVisibleSlippage(value - this.minDisplaySlippage);
    },
    /**
     * Returns a parsed value for the CR to be presented to the user.
     */
    getSlippagePercentage() {
      this.slippage = Math.floor(this.slippage * 10) / 10;
      if (Number.isNaN(this.slippage) || this.slippage === 0) return "";
      if (Number.isFinite(this.slippage) && !Number.isNaN(this.slippage))
        return `${this.slippage}%`;
      return this.slippage;
    },
    /**
     * Calculates a new slippage based on mouse position.
     */
    updateSlippage(event) {
      if (this.isReadOnly || !getMouseDown(0)) return;

      const bar = this.$refs["slippage-bar"];
      const barLeftPos = getAbsoluteElementPosition(bar).left;
      const x = (event.pageX - barLeftPos) / bar.offsetWidth;

      this.slippage =
        Math.floor(
          (x * (this.maxDisplaySlippage - this.minDisplaySlippage) +
            this.minDisplaySlippage) *
            10
        ) / 10;

      if (this.slippage > this.maxSliderSlippage)
        this.slippage = this.maxSliderSlippage;

      if (this.slippage < this.minSliderSlippage)
        this.slippage = this.minSliderSlippage;

      if (typeof this.onChange === "function") this.onChange(this.slippage);
    },
    registerDragSlippageListener() {
      const wrapper = this.$refs["slippage-wrapper"];
      const bar = this.$refs["slippage-bar"];

      // Set timeout until wrapper and bar are in the DOM.
      if (wrapper == null && bar == null)
        return setTimeout(this.registerDragSlippageListener.bind(this), 250);

      wrapper.addEventListener("mousemove", this.updateSlippage.bind(this));
      wrapper.addEventListener("click", this.updateSlippage.bind(this));
    },
    unregisterDragSlippageListener() {
      const wrapper = this.$refs["slippage-wrapper"];
      wrapper?.removeEventListener("mousemove", this.updateSlippage.bind(this));
      wrapper?.removeEventListener("click", this.updateSlippage.bind(this));
    },
  },
};
</script>

<style lang="scss" scoped>
@use "../assets/styles/handle.fi" as handle;

.uk-form-controls {
  position: relative;
}

.slippage {
  padding: 1.752rem 0 10px 0;

  .bar {
    position: relative;
    display: flex;
    height: 1.25rem;

    .red,
    .orange,
    .yellow,
    .green {
      height: 100%;
      flex: 1; // Set via JavaScript.
    }

    .red {
      background-color: handle.$error-color;
    }

    .orange {
      background-color: #ffb45a;
    }

    .yellow {
      background-color: handle.$warning-color;
    }

    .green {
      background-color: handle.$green;
    }

    .needle {
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      background-color: cyan;
      user-select: none;

      &.interactive {
        cursor: pointer;
      }

      .pointer {
        bottom: -8px;
        top: -8px;
        position: absolute;
        left: -4px;
        width: 4px;
        border: 2px solid handle.$green;
        box-shadow: 0 0 2px 2px rgb(0 0 0 / 15%);
        background-color: rgb(0 0 0 / 15%);
      }

      .data {
        position: absolute;
        top: -35px;
        width: 60px;
        left: -27px; // 7px accounting for percentage character.
        text-align: center;
        color: handle.$green;
        font-weight: 100;
        text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.4);
      }
    }
  }
}
</style>
