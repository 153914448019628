import { ethers } from "ethers";

export const queryStringToObject = () => {
  const search = window.location.search.substring(1);
  try {
    const innerObject = decodeURI(search)
      .replace(/"/g, '\\"')
      .replace(/&/g, '","')
      .replace(/=/g, '":"');
    return JSON.parse(`{"${innerObject}"}`);
  } catch (e) {
    return {};
  }
};

export const getReferral = () => {
  const storageKey = "handleprr";
  // Try to load referral stored from cookie.
  const storageObject = getCookie()[storageKey];
  if (storageObject) return storageObject;
  // Try to fetch referral from URL.
  const queryObject = queryStringToObject();
  const referralQueryParamName = "prr";
  const referralKey = Object.keys(queryObject).find(
    (x) => x.toLowerCase() === referralQueryParamName
  );
  const referral = queryObject[referralKey];
  if (!ethers.utils.isAddress(referral)) return null;
  // Save to cookie before returning.
  setCookie(storageKey, referral);
  return referral;
};

const getCookie = () => {
  const entries = (document.cookie || "").split(";");
  const object = {};
  for (let entry of entries) {
    const values = entry.split("=");
    try {
      // Will parse into a number or boolean, but throws for other strings.
      values[1] = JSON.parse(values[1]);
    } catch (error) {}
    object[values[0].replace(/ /g, "")] = values[1];
  }
  return object;
};

const setCookie = (key, value) => {
  document.cookie = `${key}=${value}; domain=handle.fi; expires=Tue, 19 Jan 2038 03:14:07 UTC;`;
};
